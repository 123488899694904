import React from "react";
// import NextButtonImg from "../../Assets/Svgs/NextButton.svg";
import NextButtonImg from "../../Assets/Svgs/rarrow.svg";
import "./Button.css";
import { useUseContext } from "../../Hooks/useUseContext";
const BackButton = ({ handleClick }) => {
  const { trackWordsStatus, setTrackWordsStatus } = useUseContext();

  function handleChange() {
    handleClick();
    setTrackWordsStatus(!trackWordsStatus);
    // handlePostRequest();
    // setTimeout(() => setTrackWordsStatus(!trackWordsStatus), 300);
  }
  return (
    <div
      onClick={handleChange}
      className="flex items-center 5xl:gap-[12px] 4xl:gap-[9px] 3xl:gap-[9px] ss:gap-[8px] gap-[8px] 5xl:py-[12px] 4xl:py-[9px] 3xl:py-[9px] ss:py-[8px] py-[8px] 5xl:px-[24px] 4xl:px-[19px] 3xl:px-[18px] ss:px-[16px] px-[16px] bg-[#13CE66] rounded-[12px] inner-shadow
    cursor-pointer"
    >
      <div className="5xl:text-[26px] 4xl:text-[21px] 3xl:text-[19px] ss:text-[18px] text-[18px] text-white">
        Next
      </div>
      <div>
        <img
          className="5xl:w-[23px] 4xl:w-[20px] 3xl:w-[19px] ss:w-[18px] w-[18px] 5xl:h-[17px] 4xl:h-[16px] 3xl:h-[15px] ss:h-[15px] h-[15px] mt-[2px]"
          src={NextButtonImg}
          alt="back"
        />
      </div>
    </div>
  );
};

export default BackButton;
