import React from "react";
// import ChartComponent from "../Dashboard/Chart/ChartComponent";
// import ChartGraphDet from "../Dashboard/Chart/ChartGraphDet";
import ChartForTwoData from "../Dashboard/Chart/ChartForTwoData";
const PracticeChart = ({ data, setDifficulty, difficulty, hover }) => {
  // console.log(difficulty, "diff");
  return (
    <section className=" 5xl:py-[24px] 4xl:py-[19px] 3xl:py-[18px] ss:py-[16px] py-[16px] 5xl:px-[48px] 4xl:px-[39px] 3xl:px-[36px] ss:px-[32px] px-[32px] bg-white rounded-[12px] flex flex-col items-center">
      <div>
        {" "}
        <div className="flex pb-[24px]">
          <section
            onClick={() => setDifficulty("E")}
            className={`${
              difficulty === "E" ? "bg-black text-white" : "bg-[#F4F4F4]"
            } flex items-center border border-transparent rounded-s-[43px]  p-[10px]
           hover:bg-black hover:text-white cursor-pointer`}
          >
            <div
              className={`bg-[#AA7C48] rounded-full w-[12px] h-[12px] `}
            ></div>
            <div className="ms-[12px] 5xl:text-[14px] 4xl:text-[13px] 3xl:text-[12px] ss:text-[12px] text-[11px]">
              Essential
            </div>
          </section>
          <section
            onClick={() => setDifficulty("U")}
            className={`${
              difficulty === "U" ? "bg-black text-white" : "bg-[#F4F4F4]"
            } flex items-center  border border-transparent rounded-e-[43px]  p-[10px]
           hover:bg-black hover:text-white cursor-pointer`}
          >
            <div
              className={`bg-[#808080] rounded-full w-[12px] h-[12px]`}
            ></div>
            <div className="ms-[12px] 5xl:text-[14px] 4xl:text-[13px] 3xl:text-[12px] ss:text-[12px] text-[11px]">
              Uncategorized
            </div>
          </section>
        </div>
      </div>
      <div className="relative">
        <ChartForTwoData
          data={data}
          setDifficulty={setDifficulty}
          hover={hover}
        />
        <div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
        cursor-pointer text-center text-[18px] "
        >
          <p
            onClick={() => setDifficulty("all")}
            className={`${
              difficulty === "all" ? "bg-[#F4F4F4] " : "bg-white"
            } hover:bg-[#F4F4F4] px-[40px] py-[38px] rounded-[50%] `}
          >
            All
          </p>
        </div>
      </div>
    </section>
  );
};

export default PracticeChart;
