import React from "react";

const Instructions = ({ textSingular, textPlural }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="bg-[#FFF5EB] px-[12px] py-[24px] w-[350px]">
      <main className=" flex flex-col gap-[10px] text-[14px]">
        <li className="ms-[12px] 5xl:text-[14px] 4xl:text-[11px] 3xl:text-[10.5px] ss:text-[9.35px] text-[9.35px]">
          The card set has maximum 5 curated {textPlural}.
        </li>
        <li className="ms-[12px] 5xl:text-[14px] 4xl:text-[11px] 3xl:text-[10.5px] ss:text-[9.35px] text-[9.35px]">
          Click on a {textSingular} to practice.
        </li>
        <li className="ms-[12px] 5xl:text-[14px] 4xl:text-[11px] 3xl:text-[10.5px] ss:text-[9.35px] text-[9.35px]">
          {/* The clicked {textPlural} can be accessed in “
          {capitalizeFirstLetter(textPlural)} Practiced” section in the
          Dashboard. */}
          The selected {textSingular} can be accessed in the "{textPlural}{" "}
          Practiced" section in the Dashboard only if you click any of the
          learning options in the contextual practice section.
        </li>
        <li className="ms-[12px] 5xl:text-[14px] 4xl:text-[11px] 3xl:text-[10.5px] ss:text-[9.35px] text-[9.35px]">
          Ignore {textPlural} you already know.
        </li>
        <li className="ms-[12px] 5xl:text-[14px] 4xl:text-[11px] 3xl:text-[10.5px] ss:text-[9.35px] text-[9.35px]">
          Press ‘Next’ only after the 5 {textPlural} you see on this card are
          known to you. These {textPlural} can be accessed in “
          {capitalizeFirstLetter(textPlural)} I Know” section in the Dashboard.
        </li>
      </main>
    </div>
  );
};

export default Instructions;
