import React from "react";
import StartPracticePhrases from "../Components/PracticeWords/StartPracticePhrases";
import { useFetch } from "../Hooks/useFetch";
import Sidebar from "../Components/Sidebar/Sidebar.jsx";
const StartLearningPhrases = () => {
  //for scraped data
  const apiKey = process.env.REACT_APP_API_URL;
  const { data } = useFetch({
    URL: `${apiKey}/metrics`,
    fetchId: "data",
  });
  // console.log(data);
  return (
    <div className="bg-[#FBF9F8]  min-h-screen h-full flex">
      <div className="sticky top-0 h-screen bg-white">
        <Sidebar />
      </div>
      <div className="w-full flex">
        <StartPracticePhrases data={data} />
      </div>
    </div>
  );
};

export default StartLearningPhrases;
