import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Line from "../../Assets/Svgs/Line.svg";
import templategreen from "../../Assets/Svgs/green.svg";
import success from "../../Assets/Svgs/success.svg";
import lock from "../../Assets/Svgs/lock.svg";
import add from "../../Assets/Svgs/add.svg";
import brown from "../../Assets/Svgs/brown.svg";
import templategrey from "../../Assets/Svgs/grey.svg";

const DailyTwentyWord = () => {
  const [data, setData] = useState([]);
  const apiKey = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiKey}/get-today-words?text_type=word`,
        {
          withCredentials: true,
        }
      );
      setData(response.data);
      // console.log(data, "*************************");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const greenColors = [templategreen, "#009883", "#F2FBF1", success, "#FFFFFF"];
  const brownColors = [brown, "#AA7C48", "#FFFFFF", add, "#FFF5EB"];
  const greyColors = [templategrey, "#AEAEAE", "#F1F3FB", lock, "#FFFFFF"];

  const getBackground = (index, practisedWords, totalWords) => {
    if (practisedWords === totalWords || practisedWords >= 1)
      return greenColors;
    if (
      index === 0 ||
      (index > 0 && data[index - 1]?.practised_words == totalWords)
    ) {
      return brownColors;
    }
    return greyColors;
  };

  const isClickable = (index, practisedWords, totalWords) => {
    if (practisedWords >= 1) return true;
    if (
      index === 0 ||
      (index > 0 && data[index - 1]?.practised_words == totalWords)
    ) {
      return true;
    }
    return false;
  };

  const getText = (index, practisedWords, totalWords) => {
    if (practisedWords === totalWords) {
      return "Practice Done";
    } else if (practisedWords >= 1) {
      return "Start Practice";
    } else {
      return "Click to practice";
    }
  };

  return (
    <>
      <div>
        {data.length > 0 && (
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="flex flex-col min-w-[40%]">
                <div
                  className="5xl:text-[24px] 4xl:text-[19px] 3xl:text-[18px] ss:text-[16px] text-[16px] font-bold leading-10"
                  style={{ fontFamily: "Cardo" }}
                >
                  Practice | Daily Words
                </div>
                <div className="font-normal 5xl:text-[18px] 4xl:text-[16px] 3xl:text-[13.5px] ss:text-[12px] text-[12px]">
                  Each set containing 20 words from the videos you've watched
                  and online news articles you've read.
                </div>
              </div>
              <div className=" flex items-center">
                <div className="h-0.5 w-full bg-[#aa7c48]"></div>
              </div>
            </div>
            <div className="flex gap-4 overflow-x-auto scroll-smooth max-w-none">
              {Object.keys(data).map((key, index) => (
                <div
                  key={key}
                  style={{
                    cursor: isClickable(
                      index,
                      data[key].practised_words,
                      data[key].total_words
                    )
                      ? "pointer"
                      : "not-allowed",
                  }}
                >
                  <div
                    className={`p-[10px] rounded-t-[18px] text-center 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-xs text-xs text-none bg-[${
                      getBackground(
                        index,
                        data[key].practised_words,
                        data[key].total_words
                      )[1]
                    }] text-[${
                      getBackground(
                        index,
                        data[key].practised_words,
                        data[key].total_words
                      )[1]
                    }]`}
                  >
                    .
                  </div>
                  <div
                    style={{
                      backgroundColor: getBackground(
                        index,
                        data[key].practised_words,
                        data[key].total_words
                      )[2],
                    }}
                    className={`main flex flex-col p-4 gap-4 5xl:w-72 4xl:w-60 3xl:w-60 ss:w-42 w-42 items-center rounded-b ${!isClickable(
                      index,
                      data[key].practised_words,
                      data[key].total_words
                    )}`}
                  >
                    <div
                      className="first text-[#2E2E3080] 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-xs text-xs font-normal leading-4"
                      style={{ fontFamily: "lato" }}
                    >
                      <p
                        style={{
                          color:
                            getBackground(
                              index,
                              data[key].practised_words,
                              data[key].total_words
                            ) === greenColors
                              ? "#2E2E3080"
                              : "#2E2E30",
                        }}
                      >
                        {getBackground(
                          index,
                          data[key].practised_words,
                          data[key].total_words
                        ) === greenColors
                          ? `You have Practiced ${data[key].practised_words}/${data[key].total_words} Words`
                          : "Start Practice"}
                      </p>
                    </div>
                    <div className="second">
                      <img src={Line} alt="line" />
                    </div>
                    <div
                      className="w-40 h-28 flex justify-center items-center bg-center bg-no-repeat"
                      style={{
                        backgroundImage: `url(${
                          getBackground(
                            index,
                            data[key].practised_words,
                            data[key].total_words
                          )[0]
                        })`,
                        backgroundSize: "85%",
                      }}
                    >
                      <div className="third-text flex flex-col bg-white pt-0  px-2 rounded-lg">
                        <div
                          className="text-first text-sm font-bold text-[#2E2E30] pt-1 leading-5"
                          style={{ fontFamily: "cardo" }}
                        >
                          SET
                        </div>
                        <div
                          className="text-second flex justify-center text-[#2E2E30] text-3xl font-bold leading-9"
                          style={{ fontFamily: "cardo" }}
                        >
                          <div>{parseInt(key) + 1}</div>
                        </div>
                      </div>
                    </div>
                    <div className="fourth">
                      <img src={Line} alt="line" />
                    </div>
                    <div
                      className="fifth flex flex-col items-center gap-4 p-2 rounded-lg w-full"
                      style={{
                        backgroundColor: getBackground(
                          index,
                          data[key].practised_words,
                          data[key].total_words
                        )[4],
                      }}
                    >
                      {isClickable(
                        index,
                        data[key].practised_words,
                        data[key].total_words
                      ) ? (
                        <Link
                          to="/daily-five-words"
                          state={{ section_id: data[key].section }}
                          className="w-full"
                        >
                          <div
                            className="flex flex-col justify-center items-center gap-4"
                            onClick={fetchData}
                          >
                            <div>
                              <img
                                src={
                                  getBackground(
                                    index,
                                    data[key].practised_words,
                                    data[key].total_words
                                  )[3]
                                }
                                alt="practice"
                              />
                            </div>
                            <div
                              className={`${
                                getBackground(
                                  index,
                                  data[key].practised_words,
                                  data[key].total_words
                                ) === greenColors
                                  ? "text-[14px]"
                                  : "text[16px] font-bold"
                              }`}
                              style={{
                                color:
                                  getBackground(
                                    index,
                                    data[key].practised_words,
                                    data[key].total_words
                                  ) === greenColors
                                    ? "#019883"
                                    : "#AA7C48",
                              }}
                            >
                              {/* {getBackground(
                                index,
                                data[key].practised_words,
                                data[key].total_words
                              ) === greenColors
                                ? "Click To Practice"
                                : "Start Practice"} */}
                              {getText(
                                index,
                                data[key].practised_words,
                                data[key].total_words
                              )}
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <div className="text-gray-400 text-[14px] cursor-not-allowed">
                          <div className="flex flex-col justify-center items-center gap-4">
                            <div>
                              <img
                                src={
                                  getBackground(
                                    index,
                                    data[key].practised_words,
                                    data[key].total_words
                                  )[3]
                                }
                                alt="practice"
                              />
                            </div>
                            <div className="text-[#AEAEAE] font-bold text-[14px]">
                              Unlocked
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DailyTwentyWord;
