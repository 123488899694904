import React from "react";
import BarAxis from "./Graph/BarAxis";
import Bar from "./Graph/Bar";

const Graph = ({ data }) => {
  // console.log(data, "filter");
  let wordsCollectedtime = data.data
    ? data.data.map((el) => el.article + el.video + el.manual + el.admin)
    : []; //individual data on time basis
  let wordsByTime = data.data ? data.data.map((el) => el.time) : []; //time of individual data
  // let wordsCollectedtime = Object.values(data); //individual data on time basis
  // let wordsByTime = Object.keys(data); //time of individual data
  // console.log(data);
  let wordsByInterval = data.data ? data.data.map((el) => el.interval) : [];
  // console.log(data.data);
  let interval = wordsByInterval[0];
  let highestData = Math.max(...wordsCollectedtime); //highest word collected on time basis
  let wordsCollectedBySource = data.data
    ? data.data.map((el) => ({
        article: el.article,
        video: el.video,
        manual: el.manual,
        admin: el.admin,
      }))
    : []; //individual data by sources
  //graph data
  let graphData = wordsCollectedtime.map((el, i) => {
    return (
      <Bar
        interval={interval}
        key={i}
        data={wordsByTime}
        singleData={el}
        index={i}
        highestData={highestData}
        individualData={wordsCollectedBySource}
      />
    );
  });
  //check if any data is present
  let isDataPresent =
    wordsCollectedtime.length > 0 ? (
      graphData
    ) : (
      <p className=" m-auto">No Results found</p>
    );

  return (
    <div className=" mt-[48px]  bg-white rounded-[12px]">
      <main className="py-[24px]">
        {" "}
        <section className="px-[48px] rounded-[12px]">
          <div className="text-[18px]">Here's how much you've Glibztered.</div>
        </section>
        <section className="pt-[48px] ps-[48px] pb-[24px] flex">
          <div className="me-[24px]">
            {wordsCollectedtime.length > 0 ? (
              <BarAxis data={highestData} />
            ) : (
              <p className="h-[250px]"></p>
            )}
          </div>
          <div className="flex grow me-[48px] gap-[48px] ">{isDataPresent}</div>
        </section>
      </main>
    </div>
  );
};

export default Graph;
