// App.js
import React, { useState } from "react";
import translateText from "../../util/translateText";
const Language = [
  {
    value: "hi-IN",
    lang: "Hindi",
  },
  {
    value: "ta-IN",
    lang: "Tamil",
  },
  {
    value: "te-IN",
    lang: "Telugu",
  },
  {
    value: "ml-IN",
    lang: "Malayalam",
  },
  {
    value: "kn-IN",
    lang: "Kannada",
  },
  {
    value: "bn-IN",
    lang: "Bangla",
  },
  {
    value: "mr-IN",
    lang: "Marathi",
  },
  {
    value: "gu-IN",
    lang: "Gujarati",
  },
  {
    value: "pa-IN",
    lang: "Punjabi",
  },
  {
    value: "ur-IN",
    lang: "Urdu",
  },
  {
    value: "or-IN",
    lang: "Odia",
  },
  {
    value: "as-IN",
    lang: "Assamese",
  },
  {
    value: "fr-FR",
    lang: "French",
  },
  {
    value: "es-ES",
    lang: "Spanish",
  },
  {
    value: "de-DE",
    lang: "German",
  },
  {
    value: "ru-RU",
    lang: "Russian",
  },
  {
    value: "zh-CN",
    lang: "Chinese",
  },
  {
    value: "ja-JP",
    lang: "Japanese",
  },
  {
    value: "it-IT",
    lang: "Italian",
  },
  {
    value: "pt-PT",
    lang: "Portuguese",
  },
  {
    value: "ko-KR",
    lang: "Korean",
  },
  {
    value: "ar-SA",
    lang: "Arabic",
  },
  {
    value: "el-GR",
    lang: "Greek",
  },
  {
    value: "tr-TR",
    lang: "Turkish",
  },{
    value: "sv-SE",
    lang: "Swedish",
  },
  {
    value: "nl-NL",
    lang: "Dutch",
  },
  {
    value: "fa-IR",
    lang: "Persian (Farsi)",
  },
  {
    value: "si-LK",
    lang: "Sinhala",
  }
];


function Translate({ Text, setoutputText }) {
  const [targetLanguage, setTargetLanguage] = useState("hi-IN");
  const handleTranslate = async () => {
    const translatedText = await translateText(Text, targetLanguage);
    setoutputText(translatedText);
  };

  return (
    <div>
      <button
        className="5xl:text-[14px] 4xl:text-[13px] 3xl:text-[12.5px] ss:text-[11px] text-[11px] text-white rounded-[36px] bg-[#AA7C48] p-[8px] me-[10px]"
        onClick={handleTranslate}
      >
        Translate
      </button>
      <select 

        className="p-[8px] rounded-[6px] 5xl:text-[14px] 4xl:text-[13px] 3xl:text-[12.5px] ss:text-[11px] text-[11px] overflow-y-auto"
        value={targetLanguage}
        onChange={(e) => setTargetLanguage(e.target.value)}
      >
        {Language.map((val, ind) => {
          return (
            <option key={ind} value={val.value}>
              {val.lang}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Translate;
