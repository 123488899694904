import React from "react";
import "../../Dashboard/Dashboard.css";
import PracticeButton from "../../Buttons/PracticeButton";
import { Link } from "react-router-dom";
import { numberConversion } from "../../../Helpers/numberConversion.js";

const Acquired = ({ name, data, select, setSelect, handleClick, path }) => {
  const color = {
    backgroundColor: select ? "#FFF5EB" : "white",
  };

  return (
    <div
      onClick={handleClick}
      style={color}
      className={`border-[2px] rounded-[12px] cursor-pointer
       bg-white text-center leading-none group hover:bg-[#FFF5EB] shadow-lg 5xl:px-[48px] 4xl:px-[40px] 3xl:px-[36px] ss:px-[32px] px-[32px] 5xl:py-[24px] 4xl:py-[19px] 3xl:py-[18px] ss:py-[16px] py-[16px]`}
    >
      <div className="5xl:text-[22px] 4xl:text-[18px] 3xl:text-[16.5px] ss:text-[14px] font-medium">
        {name}
      </div>

      <div className="text-[#AA7C48] 5xl:text-[64px] 4xl:text-[52px] 3xl:text-[48px] ss:text-[42px] text-[42px] leading-none font-bold font-[Cardo] 5xl:pt-[24px] 4xl:pt-[19px] 3xl:pt-[18px] ss:pt-[16px] pt-[16px] 5xl:py-[48px] 4xl:py-[39px] 3xl:py-[36px] ss:py-[32px] py-[32px]">
        {numberConversion(data)}
      </div>
      {path ? (
        <Link to="/startLearning">
          <PracticeButton select={select} setSelect={setSelect} />
        </Link>
      ) : (
        <Link to="/startLearnPhrases">
          <PracticeButton select={select} setSelect={setSelect} />
        </Link>
      )}
    </div>
  );
};

export default Acquired;
