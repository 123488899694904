import React from "react";
import StartPractice from "../Components/PracticeWords/StartPractice";
import { useFetch } from "../Hooks/useFetch";
import Sidebar from "../Components/Sidebar/Sidebar.jsx";
const StartLearning = () => {
  //for scraped data
  const apiKey = process.env.REACT_APP_API_URL;
  const { data } = useFetch({
    URL: `${apiKey}/metrics`,
    fetchId: "data",
  });
  return (
    <div className="bg-[#FBF9F8]  min-h-screen h-full flex">
      <div className="sticky top-0 h-screen bg-white">
        <Sidebar />
      </div>
      <div className="w-full flex">
        <StartPractice data={data} />
      </div>
    </div>
  );
};

export default StartLearning;

// lg:ps-[200px] 2xl:ps-[312px]
