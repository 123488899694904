import React from "react";
import "../../Dashboard/Dashboard.css";
import PracticeButton from "../../Buttons/PracticeButton";
const Sources = ({
  img,
  i,
  active,
  setActive,
  bySource,
  setSourceValue,
  source,
  value,
  setDay,
  words = 0,
}) => {
  // const isActive = id === active;
  function handleToggle() {
    setActive((prev) => ({ ...prev, UI: source }));
    setSourceValue(bySource);
    setDay(null);
  }
  return (
    <div
      key={i}
      onClick={handleToggle}
      className={`${
        source === active.UI ? `bg-[#FFF5EB]` : "bg-white"
      } 5xl:px-[48px] 4xl:px-[30px] 3xl:px-[36px] ss:px-[32px] px-[30px] 5xl:py-[24px] 4xl:py-[19px] 3xl:py-[18px] ss:py-[16px] py-[16px] border-[2px] rounded-[12px] text-center leading-none group hover:bg-[#FFF5EB] shadow-lg cursor-pointer`}
    >
      <div className="flex justify-center 5xl:pb-[24px] 4xl:pb-[19px] 3xl:pb-[18px] ss:pb-[16px] pb-[16px] ">
        <img src={img} alt="img" />
      </div>
      <div className="5xl:text-[22px] 4xl:text-[18px] 3xl:text-[16px] ss:text-[14px] text-[14px] font-medium">
        {source}
      </div>
      <div className="text-[#AA7C48] 5xl:text-[64px] 4xl:text-[53px] 3xl:text-[48px] ss:text-[42px] text-[42px] 5xl:pt-[24px] 4xl:pt-[19px] 3xl:pt-[18px] ss:pt-[16px] pt-[16px] 5xl:pb-[48px] 4xl:pb-[39px] 3xl:pb-[36px] ss:pb-[32px] pb-[32px] leading-none font-[Cardo] font-bold">
        {words}
      </div>
      <PracticeButton />
    </div>
  );
};

export default Sources;
