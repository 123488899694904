import React, { useEffect } from "react";
import WordPowerChart from "./WordPowerChart";
// import Filter from "../Filter/Filter";
import { useUseContext } from "../../Hooks/useUseContext";
import axios from "axios";
import { useNavigate } from "react-router";
import AudioRecorder from "./SpeechToText";

const WordPowerUser = ({ vocabularyStrength, phrasalStrength }) => {
  // console.log(phrasalStrength);
  const { userInfo, setUserInfo } = useUseContext();
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/user_info`, {
          withCredentials: true,
        });
        setUserInfo(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <section className="flex justify-between items-center">
        <div>
          <div className="font-bold text-[34px] font-[Cardo] ">
            <b>Hello {userInfo.user_name},</b>
          </div>
          <div className="text-[18px]">Here’s your performance on Glibzter</div>
        </div>
        <div>{/* <Filter /> */}</div>
      </section>
      <AudioRecorder/>
      {/* <section className="flex gap-[48px] mt-[48px]">
        <WordPowerChart
          name={"Vocabulary Strength"}
          vocabularyStrength={
            isFinite(vocabularyStrength) ? vocabularyStrength : 0
          }
        />
        <WordPowerChart
          name={"Phrasal Strength"}
          vocabularyStrength={isFinite(phrasalStrength) ? phrasalStrength : 0}
        />
      </section> */}
    </div>
  );
};

export default WordPowerUser;
