import React, { useState } from "react";
import Profile from "./Profile.jsx";
import Pricings from "./Pricings";

const ProfilePlans = ({ premium, setRerender }) => {
  const [toggle, setToggle] = useState(false);
  // console.log(premium, "status");
  return (
    <div className="5xl:mt-[100px] 4xl:mt-[80px] 3xl:mt-[60px] ss:mt-[40px] mt-[20px] 5xl:mx-56 4xl:mx-20 3xl:mx-8 ss:mx-0 mx-0 grid 5xl:grid-cols-8 4xl:grid-cols-8 3xl:grid-cols-5 ss:grid-cols-5 grid-cols-5  border rounded-[12px] bg-white ">
      <section className="5xl:p-[48px] 4xl:p-[48px] 3xl:p-[38px] ss:p-[28px] 5xl:col-span-2 4xl:col-span-2 3xl:col-span-1 ss:col-span-1 col-span-1 5xl:text-[18px] 4xl:text-[14px] 3xl:text-[13.5px] ss:text-[12px] text-[12px] ">
        <div
          onClick={() => setToggle(false)}
          className={`${
            !toggle ? "border-[#2E2E30]" : ""
          } border-s-[4px] 5xl:px-[24px] 4xl:px-[24px] 3xl:px-[24px] ss:px-[24px] px-[24px] py-[12px]  hover:border-[#2E2E30] cursor-pointer`}
        >
          Update Plan
        </div>
        <div
          onClick={() => setToggle(true)}
          className={`${
            toggle ? "border-[#2E2E30]" : ""
          } border-s-[4px] px-[24px] py-[12px] hover:border-[#2E2E30] cursor-pointer mt-[12px]`}
        >
          Edit Profile
        </div>
      </section>
      <section className="p-[48px] border-s 5xl:col-span-6 4xl:col-span-6 3xl:col-span-4 ss:col-span-4 col-span-4">
        {toggle && (
          <div>
            <h3 className="text-[18px] font-bold mb-[24px]">Edit Profile</h3>
            <Profile />
          </div>
        )}
        {!toggle && (
          <section>
            <div className="5xl:mb-[24px] 4xl:mb-[19px] 3xl:mb-[18px] ss:mb-[16px] mb-[16px] flex justify-between items-center">
              <h3 className="5xl:text-[18px] 4xl:text-[16px] 3xl:text-[14.5px] ss:text-[13px] text-[13px] font-bold ">
                Your Current Plan
              </h3>
              <div className=" 5xl:text-[12px] 4xl:text-[11px] 3xl:text-[10px] ss:text-[10px] text-[10px] flex  items-center">
                <div
                  className={`${
                    premium.status === "Inactive"
                      ? "bg-[#009883] border-transparent text-white"
                      : ""
                  } 5xl:py-[12px] 4xl:py-[11px] 3xl:py-[10px] ss:py-[9px] py-[9px] 5xl:px-[24px] 4xl:px-[22px] 3xl:px-[20px] ss:px-[18px] px-[18px] rounded-s-[12px]
                 border `}
                >
                  Free
                </div>
                <div
                  className={`${
                    premium.status === "Active"
                      ? "bg-[#009883] border-transparent text-white"
                      : ""
                  } text-black rounded-e-[12px]  5xl:py-[12px] 4xl:py-[11px] 3xl:py-[10px] ss:py-[9px] py-[9px] 5xl:px-[12px] 4xl:px-[11px] 3xl:px-[10px] ss:px-[9px] px-[9px] border-y border-e`}
                >
                  Premium
                </div>
              </div>
            </div>
            <Pricings premium={premium} setRerender={setRerender} />
          </section>
        )}
      </section>
    </div>
  );
};

export default ProfilePlans;
