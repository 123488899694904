import React, { useEffect, useState } from "react";
import logo from "../../Assets/Svgs/GlibzterLogo.svg";
import McqTest from "./McqTest.jsx";
import SpeechTest from "./SpeechTest.jsx";
import Source from "../Dashboard/Source";
import Result from "./Result.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const StartTest = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(null);
  const [isMcqComplete, setIsMcqComplete] = useState(() => {
    return sessionStorage.getItem("isMcqComplete") === "true";
  });
  const [isSpeechComplete, setIsSpeechComplete] = useState(() => {
    return sessionStorage.getItem("isSpeechComplete") === "true";
  });
  const testId = sessionStorage.getItem("testId");

  const [testType, setTestType] = useState(() => {
    return sessionStorage.getItem("testType") || "mcq";
  });

  const apiKey = process.env.REACT_APP_API_URL;

  // Disable back and forward navigation
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // Required for modern browsers to trigger the confirmation dialog
    };

    const handlePopState = (e) => {
      e.preventDefault();
      window.history.pushState(null, null, window.location.href); // Prevents going back
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    // Add a history entry to prevent forward/back navigation
    window.history.pushState(null, null, window.location.href);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleMcqComplete = () => {
    setIsMcqComplete(true);
    sessionStorage.setItem("isMcqComplete", "true");
    setTestType("speech");
    sessionStorage.setItem("testType", "speech");
    // console.log("MCQ test is completed");
  };

  const handleSpeechComplete = () => {
    setIsSpeechComplete(true);
    sessionStorage.setItem("isSpeechComplete", "true");
    setTestType("results");
    // console.log("Speech test is completed");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiKey}/assessment/${testId}/start-assessment/`,
          { withCredentials: true }
        );
        setQuestions(response.data);
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [apiKey, testId]);

  useEffect(() => {
    sessionStorage.setItem("testType", testType);
  }, [testType]);

  return (
    <div>
      <div className=" bg-[#FBF9F8] w-full min-h-screen ">
        <div className="w-full h-full p-[10px]">
          <div className="flex justify-between">
            <img src={logo} alt="logo" className="ml-[20px]" />
            <section className="flex justify-end pt-[20px] px-[48px]">
              <Source />
            </section>
          </div>

          <div className="flex justify-center items-center w-full my-[100px]">
            <div className="w-[70%]   rounded-[18px] bg-white">
              <div className="m-[4%]">
                {questions ? (
                  questions.mcq_questions.length > 0 ||
                  questions.speech_questions.length > 0 ? (
                    isSpeechComplete ? (
                      <Result />
                    ) : isMcqComplete ? (
                      <SpeechTest
                        questions={questions.speech_questions}
                        onTestComplete={handleSpeechComplete}
                      />
                    ) : (
                      <McqTest
                        questions={questions.mcq_questions}
                        onTestComplete={handleMcqComplete}
                      />
                    )
                  ) : (
                    <>
                      <div className="font-bold text-xl">
                        Oops! Practice Required. You need to practice atleast 10
                        words or phrases before taking the test.
                      </div>
                    </>
                  )
                ) : (
                  <div className="h-[400px] flex justify-center items-center">
                    <button
                      className="mt-[24px] h-[44px] w-[158px] rounded-[8px] border-[2px] border-[#2E2E30]"
                      onClick={() => {
                        navigate("/test-dashboard");
                      }}
                    >
                      Back to home
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartTest;
