import React from "react";
import "../../Dashboard/Dashboard.css";
import { numberConversion } from "../../../Helpers/numberConversion";
const Practice = ({ name, data, select, handleClick }) => {
  const color = {
    backgroundColor: select ? "#FFF5EB" : "white",
  };
  return (
    <div
      onClick={handleClick}
      style={color}
      className="flex items-center bg-white 5xl:px-[48px] 4xl:px-[39px] 3xl:px-[36px] ss:px-[32px] px-[32px] 5xl:py-[24px] 4xl:py-[19px] 3xl:py-[18px] ss:py-[16px] py-[16px] border-[2px] rounded-[12px] 5xl:gap-[48px] 4xl:gap-[39px] 3xl:gap-[36px] ss:gap-[32px] gap-[32px] shadow-lg"
    >
      <div className="5xl:text-[22px] 4xl:text-[18px] 3xl:text-[16.5px] ss:text-[14px] text-[14px] font-medium">
        {name}
      </div>
      <div className="text-[#AA7C48] 5xl:text-[64px] 4xl:text-[52px] 3xl:text-[48px] ss:text-[42px] text-[42px] font-bold leading-none font-[Cardo]">
        {numberConversion(data)}
      </div>
    </div>
  );
};

export default Practice;
