import React, { useEffect, useState } from "react";
import Sources from "./PracticeSession/Sources";
import Logo from "../../Assets/Svgs/GlibzterLogo-transparent.svg";
import BackArrow from "../../Assets/Svgs/BackArrow.svg";
import AllCollection from "../../Assets/Svgs/AllCollection.svg";
import YouTubeCollection from "../../Assets/Svgs/YoutubeCollection.svg";
import ArticlesCollection from "../../Assets/Svgs/ArticlesCollection.svg";
import ManualCollection from "../../Assets/Svgs/ManualCollection.svg";
import PracticeChart from "./PracticeChart";
import Words from "./PracticeSession/Words";
import { Link } from "react-router-dom";
import { useFetchPracticePhrase } from "../../Hooks/useFetchPracticePhrase";
import { useUseContext } from "../../Hooks/useUseContext";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
// import Filter from "../Filter/Filter";
import { numberConversion } from "../../Helpers/numberConversion";
import Instructions from "./PracticeSession/Instructions";
const StartPractice = ({ data }) => {
  const apiKey = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  //context
  const {
    active,
    setActive,
    difficulty,
    setDifficulty,
    setSourceValue,
    day,
    setDay,
    // // postData,
    // setPostData,
  } = useUseContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectHome = queryParams.get("day");
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  //state
  const [adminPhrases, setAdminphrases] = useState([]);
  const [render, setRender] = useState(true);

  //effects
  useEffect(() => {
    if (day) {
      sessionStorage.setItem("userday", JSON.stringify(day));
    }
  }, []);
  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("userday"));
    if (storedData) setDay(storedData);
  }, []);
  function removeItem() {
    setDay(null);
    sessionStorage.removeItem("userday");
  }

  useEffect(() => {
    const fetchData = async () => {
      if (redirectHome) {
        try {
          await axios.get(
            `${apiKey}/admin_add?day=${redirectHome}&type=phrase`,
            {
              withCredentials: true,
            }
          );

          const response = await axios.get(
            `${apiKey}/texts_for_practise_admin?day=${redirectHome}&difficulty=all&source=admin&type=phrase`,
            {
              withCredentials: true,
            }
          );
          // console.log(response.data);
          setAdminphrases(response.data.texts);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            handleRemoveCookies();
            navigate("/");
          }
        }
      }
    };
    fetchData();
  }, [render]);
  const { practiceWords } = useFetchPracticePhrase({
    URL: `${apiKey}/texts_for_practise`,
  });

  // <---!--->
  //for check if it is practised or not ,individual single data

  let appendStatus = practiceWords.map((el) => {
    return { text: el.word, status: "practised" };
  });

  let postData;
  function handleClick(i) {
    postData = appendStatus.filter((item, ind) => {
      if (ind === i) {
        return { ...item, status: "practised" };
      }
      return false;
    });

    handlePostRequest();
  }
  //for individual single data
  const handlePostRequest = async () => {
    if (postData) {
      try {
        //api for practised words
        await axios.post(`${apiKey}/mark_practised_text`, postData, {
          withCredentials: true,
        });
        // console.log("Post successful:", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    }
  };
  // <---!--->

  //for check if it is practised or not ,for multiple data
  // <---!--->
  let skipped = practiceWords.map((el) => {
    return { text: el.word, status: "skipped" };
  });
  let skippedAdminPhrase = adminPhrases.map((el) => {
    return { text: el.word, status: "skipped" };
  });
  // console.log(skippedAdminPhrase)
  async function handleAdminSkip() {
    setRender(!render);
    if (skippedAdminPhrase) {
      try {
        //api for practised words
        await axios.post(`${apiKey}/mark_practised_text`, skippedAdminPhrase, {
          withCredentials: true,
        });
        // console.log("Post successful:", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    }
  }
  const handleSkipAll = async () => {
    if (skipped) {
      try {
        //api for practised words
        await axios.post(`${apiKey}/mark_practised_text`, skipped, {
          withCredentials: true,
        });
        // console.log("Post successful:", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    }
  };
  // <---!--->
  //main variables
  let articleWords = data.acquired?.phrase?.article || 0;
  let videoWords = data.acquired?.phrase?.video || 0;
  let manualWords = data.acquired?.phrase?.manual || 0;
  // total words acquired calculation

  let totalWords =
    (articleWords.E || 0) +
    (articleWords.U || 0) +
    (videoWords.E || 0) +
    (videoWords.U || 0) +
    (manualWords.E || 0) +
    (manualWords.U || 0);

  let EssentialWords = Math.round(
    (((articleWords.E || 0) + (videoWords.E || 0) + (manualWords.E || 0)) /
      totalWords) *
      100
  );

  let UncategorizedWords = Math.round(
    (((articleWords.U || 0) + (videoWords.U || 0) + (manualWords.U || 0)) /
      totalWords) *
      100
  );
  const practiceAllData = [
    { value: EssentialWords || 0 },
    { value: UncategorizedWords || 0 },
  ];
  //words Acquired by the source
  let totalWordFromArticles = (articleWords.E || 0) + (articleWords.U || 0);

  let totalWordFromVideos = (videoWords.E || 0) + (videoWords.U || 0);
  let totalWordFromManual = (manualWords.E || 0) + (manualWords.U || 0);
  // article words acquired calculation

  let articleEssentialWords = Math.round(
    ((articleWords.E || 0) / totalWordFromArticles) * 100
  );
  let articleUncategorizedWords = Math.round(
    ((articleWords.U || 0) / totalWordFromArticles) * 100
  );

  const practiceArticleData = [
    { value: articleEssentialWords || 0 },
    { value: articleUncategorizedWords || 0 },
  ];
  // Video words acquired calculation

  let videoEssentialWords = Math.round(
    ((videoWords.E || 0) / totalWordFromVideos) * 100
  );
  let videoUncategorizedWords = Math.round(
    ((videoWords.U || 0) / totalWordFromVideos) * 100
  );

  const practiceVideoData = [
    { value: videoEssentialWords || 0 },
    { value: videoUncategorizedWords || 0 },
  ];

  // manual words acquired calculation

  let manualEssentialWords = Math.round(
    ((manualWords.E || 0) / totalWordFromManual) * 100
  );
  let manualUncategorizedWords = Math.round(
    ((manualWords.U || 0) / totalWordFromManual) * 100
  );
  const practiceManualData = [
    { value: manualEssentialWords || 0 },
    { value: manualUncategorizedWords || 0 },
  ];
  // const practiceVideoData = [{ value: 0 }, { value: 0 }, { value: 0 }];
  //to check the value is greater than zero
  const isPracticedAllData = practiceAllData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );

  const isPracticedArticleData = practiceArticleData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );

  const isPracticedVideoData = practiceVideoData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );
  const isPracticedManualData = practiceManualData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );

  //source mapping
  let source = [
    {
      id: 0,
      words: numberConversion(totalWords),
      img: AllCollection,
      source: "All",
      bySource: "all",
    },
    {
      id: 1,
      words: numberConversion(totalWordFromVideos),
      img: YouTubeCollection,
      source: "Videos",
      bySource: "video",
    },
    {
      id: 2,
      words: numberConversion(totalWordFromArticles),
      img: ArticlesCollection,
      source: "Online Articles",
      bySource: "article",
    },

    {
      id: 3,
      words: numberConversion(totalWordFromManual),
      img: ManualCollection,
      source: "Manual",
      bySource: "manual",
    },
  ];
  let dataBySource = source.map((el, i) => (
    <Sources
      key={i}
      words={el.words}
      img={el.img}
      bySource={el.bySource}
      source={el.source}
      active={!day && active}
      setActive={setActive}
      setSourceValue={setSourceValue}
      setDay={setDay}
    />
  ));
  return (
    <main className="p-12 mx-auto ">
      {" "}
      <section>
        <img className=" cursor-pointer" src={Logo} alt="logo" />
      </section>
      <section className="flex justify-between py-[48px]">
        <div className="flex items-center">
          {" "}
          <Link
            to={redirectHome ? `/home` : `/dashboard`}
            onClick={() => removeItem()}
          >
            {" "}
            <img
              onClick={() => setDifficulty("all")}
              className=" cursor-pointer"
              src={BackArrow}
              alt="arrow"
            />
          </Link>
          <p className="ms-[12px] 5xl:text-[30px] 4xl:text-[24px] 3xl:text-[22px] ss:text-[20px] text-[20px] font-[Cardo]">
            Phrases Acquired
          </p>
        </div>
        <div>
          {/* <Filter /> */}
          {/* {filter.UI} */}
        </div>
      </section>
      <div className="">
        {" "}
        <section className="flex 5xl:gap-[48px] 4xl:gap-[39px] 3xl:gap-[36px] ss:gap-[32px] gap-[30px] justify-between">
          {dataBySource}
        </section>
        <section className="flex 5xl:gap-[48px] 4xl:gap-[39px] 3xl:gap-[36px] ss:gap-[32px] gap-[30px] py-[48px] justify-between">
          <div>
            {active.UI === "All" &&
              (isPracticedAllData ? (
                <PracticeChart
                  difficulty={difficulty}
                  setDifficulty={setDifficulty}
                  data={practiceAllData}
                  hover={true}
                />
              ) : (
                <NoResults />
              ))}
            {active.UI === "Videos" &&
              (isPracticedVideoData ? (
                <PracticeChart
                  difficulty={difficulty}
                  setDifficulty={setDifficulty}
                  data={practiceVideoData}
                  hover={true}
                />
              ) : (
                <NoResults />
              ))}
            {active.UI === "Online Articles" &&
              (isPracticedArticleData ? (
                <PracticeChart
                  difficulty={difficulty}
                  setDifficulty={setDifficulty}
                  data={practiceArticleData}
                  hover={true}
                />
              ) : (
                <NoResults />
              ))}
            {active.UI === "Manual" &&
              (isPracticedManualData ? (
                <PracticeChart
                  difficulty={difficulty}
                  setDifficulty={setDifficulty}
                  data={practiceManualData}
                  hover={true}
                />
              ) : (
                <NoResults />
              ))}
            {/* instructions for practice phrase */}
            <section className="5xl:mt-[48px] 4xl:mt-[39px] 3xl:mt-[36px] ss:mt-[32px] mt-[32px]">
              <Instructions textSingular="phrase" textPlural="phrases" />
            </section>
          </div>
          <div className="w-full">
            <Words
              practiceText="Click to practice any phrase"
              redirect={day ? "day" : "phrase"}
              data={day ? adminPhrases : practiceWords}
              difficulty={difficulty}
              setDifficulty={setDifficulty}
              handleClick={handleClick}
              handleSkipAll={day ? handleAdminSkip : handleSkipAll}
              day={day}
              gptRedirect={"phrase"}
              // handlePostRequest={handlePostRequest}
            />
          </div>
        </section>
      </div>
    </main>
  );
};

export default StartPractice;

function NoResults() {
  return (
    <p className="flex items-center justify-center h-[250px] w-[250px] bg-white rounded-[12px]">
      No Results Found
    </p>
  );
}
