import React, { useState, useEffect } from "react";
import axios from "axios";
import record from "../../Assets/Svgs/record.svg";
import { useNavigate } from "react-router-dom";
import AudioRecorder from "./AudioRecorder";
import GreyEdit from "../../Assets/Svgs/GreyEdit.svg";
import BlackEdit from "../../Assets/Svgs/BlackEdit.svg";
import warning from "../../Assets/Svgs/notifi.svg";
import celebrateGreen from "../../Assets/Svgs/CelebrateGreen.svg";
import sorrow from "../../Assets/Svgs/sorrow.svg";
import { Rating } from "react-simple-star-rating";
import disclaimer from "../../Assets/Svgs/disclaimer.svg";
// import Result from "./Result";

const SpeechTest = ({ questions, onTestComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const savedIndex = parseInt(
      sessionStorage.getItem("speechCurrentQuestionIndex"),
      10
    );
    return isNaN(savedIndex) ? 0 : savedIndex;
  });
  const navigate = useNavigate();
  const [convertedText, setConvertedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [quit, setQuit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [isTextareaDisabled, setIsTextareaDisabled] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [rating, setRating] = useState("");
  // const [isResult, setIsResult] = useState(false);

  const testId = sessionStorage.getItem("testId");
  const apiKey = process.env.REACT_APP_API_URL;

  const handleTextUpdate = (text) => {
    setConvertedText(text);
  };

  const handleClearText = () => {
    setConvertedText("");
  };

  const handleTextareaChange = (e) => {
    setConvertedText(e.target.value);
  };

  const handleLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (convertedText) {
      setLoading(false);
    }
  }, [convertedText]);

  useEffect(() => {
    sessionStorage.setItem("speechCurrentQuestionIndex", currentQuestionIndex);
    if (currentQuestionIndex >= questions.length) {
      setCurrentQuestionIndex(0);
    }
  }, [currentQuestionIndex, questions.length]);

  const currentQuestion = questions[currentQuestionIndex];
  const totalQuestions = questions.length;

  const submitAnswer = async () => {
    const answerPayload = {
      assessment_id: testId,
      question_id: currentQuestion.id,
      user_answer: convertedText,
    };

    try {
      const response = await axios.post(
        `${apiKey}/assessment/user-speech-answer/`,
        answerPayload,
        {
          withCredentials: true,
        }
      );
      // console.log(response, "------------speech ans--------------");
      if (response.data) {
        setIsSubmit(false);
        setIsTextareaDisabled(true);
        setRating(response.data.rating);
        setSuggestion(response.data.suggestion);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const moveToNextQuestion = () => {
    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < questions.length) {
      setCurrentQuestionIndex(nextIndex);
      setConvertedText("");
      setIsTextareaDisabled(false);
    } else {
      sessionStorage.removeItem("speechCurrentQuestionIndex");
      if (onTestComplete) {
        onTestComplete();
      }
    }
  };

  const handleNext = async () => {
    moveToNextQuestion();
    setIsSubmit(true);
  };

  if (!currentQuestion) {
    return <p>Loading question...</p>;
  }

  const handleQuit = async () => {
    try {
      // console.log(testId, "-----");
      const quit = await axios.post(
        `${apiKey}/assessment/quit-assessment/`,
        {
          assessment_id: testId,
        },
        {
          withCredentials: true,
        }
      );
      if (sessionStorage.getItem("testId")) {
        sessionStorage.removeItem("testId");
      }
      if (sessionStorage.getItem("isMcqComplete")) {
        sessionStorage.removeItem("isMcqComplete");
      }
      if (sessionStorage.getItem("isSpeechComplete")) {
        sessionStorage.removeItem("isSpeechComplete");
      }
      if (sessionStorage.getItem("testType")) {
        sessionStorage.removeItem("testType");
      }
      if (sessionStorage.getItem("mcqCurrentQuestionIndex")) {
        sessionStorage.removeItem("mcqCurrentQuestionIndex");
      }
      if (sessionStorage.getItem("speechCurrentQuestionIndex")) {
        sessionStorage.removeItem("speechCurrentQuestionIndex");
      }
      // setIsResult(true);
      navigate("/test-dashboard");
      // console.log(quit.data);
    } catch (e) {
      console.log(e);
    }
  };

  const renderContentByRating = () => {
    switch (rating) {
      case 1:
        return {
          message: "Poor Response!",
          image: sorrow,
        };
      case 2:
        return {
          message: "Poor Response!",
          image: sorrow,
        };
      case 3:
        return {
          message: "Fair Response!",
          image: celebrateGreen,
        };
      case 4:
        return {
          message: "Very Good Response!",
          image: celebrateGreen,
        };
      case 5:
        return {
          message: "Perfect response!",
          image: celebrateGreen,
        };
      default:
        return {
          message: "",
          image: "",
        };
    }
  };
  const { message, image } = renderContentByRating();

  return (
    <div>
      {/* {isResult ? (
        <Result />
      ) : ( */}
      <div>
        <div className="flex justify-between">
          <div
            className="font-bold text-[#2E2E30] text-2xl 3xl:text-[26px]"
            style={{ fontFamily: "cardo" }}
          >
            Section 2: Speech / Writing based assessment
          </div>
          <button
            className="border-[2px] border-[#009883] rounded-md w-[112px] h-[44px] 3xl:w-[124px] 3xl:h-[48px] text-base 3xl:text-[17px] text-[#009883]"
            style={{ fontFamily: "lato" }}
            onClick={() => {
              setQuit(true);
            }}
          >
            Quit
          </button>
        </div>

        <div className="flex justify-center my-5">
          {questions.map((_, index) => (
            <div
              key={index}
              className={`flex-1 h-2 mx-1 rounded ${
                index <= currentQuestionIndex ? "bg-[#009883]" : "bg-[#FBF9F8]"
              } h-[8px] rounded-[40px] mx-1`}
            ></div>
          ))}
        </div>

        <div
          className="text-lg font-semibold mt-[56px]"
          style={{ fontFamily: "lato" }}
        >
          {currentQuestion.question}
        </div>

        <div className="flex gap-[36px] mt-[56px]">
          <div>
            <img src={record} alt="record image" />
          </div>
          <textarea
            className=" w-[70%] border-[2px] border-[#E9E9E9] rounded-[9px] p-[20px]"
            placeholder={
              loading
                ? "Loading..."
                : "Your response will be visible here, you can edit your response after recording."
            }
            value={convertedText}
            onChange={handleTextareaChange}
            disabled={isTextareaDisabled}
          ></textarea>

          {convertedText ? (
            <img
              src={BlackEdit}
              alt="Edit"
              className="relative right-20 top-9 3xl:top-10 4xl:top-11 5xl:top-12"
            />
          ) : (
            <img
              src={GreyEdit}
              alt="Edit"
              className="relative right-20 top-9 3xl:top-10 4xl:top-11 5xl:top-12"
            />
          )}
        </div>

        <div className="flex justify-between mt-[50px]">
          {isSubmit && (
            <>
              <div className=" w-full">
                <AudioRecorder
                  onTextUpdate={handleTextUpdate}
                  onClearText={handleClearText}
                  onLoading={handleLoading}
                />
              </div>
              <div
                className={`${convertedText ? "flex justify-end" : "hidden"} `}
              >
                <button
                  onClick={submitAnswer}
                  className="px-4 py-2 text-white font-bold text-base 3xl:text-[17px] w-[212px] 3xl:w-[239px] h-[44px] 3xl:h-[48px] rounded-[6px] bg-[#009883]"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>

        {!isSubmit && (
          <>
            <div className="border border-[#D3D3D3] rounded-[12px] p-[20px]">
              <div className="flex justify-between">
                <div className="flex gap-[8px]">
                  <div
                    className={`font-bold text-2xl  ${
                      rating > 2 ? "text-[#009883]" : "text-[#DB3D46]"
                    }`}
                    style={{ fontFamily: "cardo" }}
                  >
                    {message}
                  </div>
                  {image && (
                    <img
                      src={image}
                      alt="feedback"
                      className="w-[26px] h-[26px]"
                    />
                  )}
                </div>
                <div>
                  <div className="flex gap-[8px]">
                    <div>
                      <Rating
                        readonly="true"
                        fillColor="#E4AA19"
                        size={27}
                        allowFraction="true"
                        initialValue={rating}
                        SVGstyle={{ display: "inline" }}
                      />
                    </div>
                    <div
                      className="bg-[#E4AA19] font-bold text-2xl text-white rounded-[100px] w-[54px] h-[30px] text-center"
                      style={{ fontFamily: "cardo" }}
                    >
                      {rating}/5
                    </div>
                  </div>
                </div>
              </div>
              {suggestion && (
                <>
                  <div className="mt-[12px] bg-[#F5F5F5] rounded-[12px] p-[24px]">
                    <div className="text-center text-[#2E2E30] font-bold text-xl">
                      Suggestion
                    </div>
                    <div className="text-center mt-[18px] text-[#828282] text-base leading-[22px]">
                      {suggestion}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleNext}
                // disabled={!convertedText}
                className={` 
                
              bg-[#172128] px-4 py-2 text-white font-bold text-base 3xl:text-[17px] w-[212px] 3xl:w-[239px] h-[44px] 3xl:h-[48px] rounded-[6px] mt-[32px]`}
              >
                {currentQuestionIndex === totalQuestions - 1
                  ? "SUBMIT TEST"
                  : "NEXT"}
              </button>
            </div>
          </>
        )}

        <div className="flex gap-2 mt-[32px]">
          <img src={disclaimer} alt="disclaimer" className=" mb-4" />
          <p className="text-[#737D87] text-sm">
            The auto-suggested response, on some occasions may not use the word
            or phrase you were asked to use while recording or writing your
            response. We at Glibzter will look to improve the quality of
            assessments as we continue to fine-tune this product. For any
            assistance, reach out to{" "}
            <a href="mailto:support@glibzter.com">support@glibzter.com</a>.
          </p>
        </div>

        {quit && (
          <div className="fixed inset-0 bg-white bg-opacity-0 flex items-center justify-center z-50">
            <div className="bg-white w-[80%] flex justify-center rounded-[16px] shadow-custom-shadow">
              <div className=" w-[60%]">
                <div className="flex justify-center">
                  <img
                    src={warning}
                    alt="warning"
                    className="w-[80px] mt-[34px] mb-[24px]"
                  />
                </div>
                <div className="font-medium text-[16px] 3xl:text-[18px] text-center 5xl:mx-[25%] mx-[15%]">
                  Are you sure you want to quit? If you do, you won’t be able to
                  resume your test.
                </div>
                <div className="mt-[24px] flex justify-between 5xl:mx-[29%] mx-[18%] 3xl:mx-[21%] 4xl:mx-[24%] mb-[34px]">
                  <button
                    className="h-[48px] bg-[#AA7C48] rounded-[12px] py-[12px] px-[64px] text-white font-semibold"
                    onClick={handleQuit}
                  >
                    Yes, Quit
                  </button>
                  <button
                    className="h-[48px] rounded-[12px] py-[12px] px-[64px] font-semibold border border-[#D0D5DD]"
                    onClick={() => {
                      setQuit(false);
                    }}
                  >
                    Resume
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* // )} */}
    </div>
  );
};

export default SpeechTest;
