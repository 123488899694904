import React, { useEffect, useState } from "react";
import Line from "../../Assets/Svgs/Line.svg";
import templategreen from "../../Assets/Svgs/green.svg";
// import templategrey from "../../Assets/Svgs/grey.svg";
// import templatebrown from "../../Assets/Svgs/brown.svg";
// import add from "../../Assets/Svgs/add.svg";
// import lock from "../../Assets/Svgs/lock.svg";
import success from "../../Assets/Svgs/success.svg";
// import swipe from "../../Assets/Svgs/swipe.svg";
import { useUseContext } from "../../Hooks/useUseContext";
// import "./Completed.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Completed = ({ day, theme, linkTo }) => {
  const apiKey = process.env.REACT_APP_API_URL;
  const { setDay } = useUseContext();
  const navigate = useNavigate();
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  const [data, setData] = useState([]);
  // console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiKey}/practice_words_per_day_admin?day=${day}`,
          {
            withCredentials: true,
          }
        );
        setData(response.data.data, "res");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      }
    };
    fetchData();
  }, []);

  const TotalWords = data.reduce((acc, curr) => {
    if (curr.type === "word") {
      acc += curr.count;
    }
    return acc;
  }, 0);

  const PractisedWords = data.reduce((acc, curr) => {
    if (
      curr.type === "word" &&
      (curr.status === "skipped" || curr.status === "practised")
    ) {
      acc += curr.count;
    }
    return acc;
  }, 0);

  const TotalPhrases = data.reduce((acc, curr) => {
    if (curr.type === "phrase") {
      acc += curr.count;
    }
    return acc;
  }, 0);
  const PractisedPhrases = data.reduce((acc, curr) => {
    if (
      curr.type === "phrase" &&
      (curr.status === "skipped" || curr.status === "practised")
    ) {
      acc += curr.count;
    }
    return acc;
  }, 0);

  // console.log(data)
  return (
    <div className="">
      <div className="bg-[#009883] p-[10px] rounded-t-[18px] text-center text-white 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-xs text-xs">
        {theme}
      </div>
      <div className="main flex flex-col p-4 gap-4 5xl:w-72 4xl:w-60 3xl:w-60 ss:w-42 w-42 items-center rounded-b bg-[#f2fbf1] ">
        <div
          className="first text-[#2E2E3080] 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-xs text-xs font-normal leading-4"
          style={{ fontFamily: "lato" }}
        >
          {linkTo ? (
            <p> You have Practiced {PractisedWords + "/" + TotalWords} Words</p>
          ) : (
            <p>
              You have Practiced {PractisedPhrases + "/" + TotalPhrases} Phrases
            </p>
          )}
        </div>
        <div className="second">
          <img src={Line} />
        </div>
        {/* <div className="third"> */}
        <div
          className="w-40 h-28 flex justify-center items-center bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${templategreen})`,
            backgroundSize: "85%",
          }}
        >
          {/* <img src={templategreen} alt="background" /> */}
          <div className="third-text flex flex-col bg-white pt-0 px-2 rounded-lg">
            <div
              className="text-first text-sm font-bold text-[#2E2E30] pt-1 leading-5"
              style={{ fontFamily: "cardo" }}
            >
              Day
            </div>
            <div
              className="text-second flex justify-center text-[#2E2E30] text-3xl font-bold leading-9"
              style={{ fontFamily: "cardo" }}
            >
              <div>{day}</div>
            </div>
          </div>
        </div>
        <div className="fourth">
          <img src={Line} />
        </div>
        <div className="fifth cursor-pointer flex flex-col items-center gap-4 bg-white p-2 rounded-lg w-full">
          <Link
            className="w-full"
            to={
              linkTo
                ? `/startLearning?day=${day}`
                : `/startLearnPhrases?day=${day}`
            }
            onClick={() => {
              setDay(day);
            }}
          >
            <div className="flex flex-col justify-center items-center gap-4">
              <div>
                <img src={success} />
              </div>
              <div className="text-[#019883] text-[14px]">
                {" "}
                Click To Practice
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Completed;
