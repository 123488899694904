import { useState, useRef, useEffect } from "react";
import axios from "axios";

function AudioRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [userSpeech, setUserSpeech] = useState("");
  const mediaRecorderRef = useRef(null);
  const [digitalMicDeviceId, setDigitalMicDeviceId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch available devices and select Digital Microphone
  const getDigitalMicrophone = async () => {
    try {
      // Request microphone access to enumerate devices (required by most browsers)
      await navigator.mediaDevices.getUserMedia({ audio: true });

      // Enumerate devices to find the available microphones
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Find the microphone labeled "Digital Microphone"
      const digitalMic = devices.find(
        (device) =>
          device.kind === "audioinput" &&
          device.label.includes("Digital Microphone")
      );

      if (digitalMic) {
        setDigitalMicDeviceId(digitalMic.deviceId);
      } else {
        console.error("Digital Microphone not found.");
        setErrorMessage(
          "Digital Microphone not found. Using default microphone."
        );
      }
    } catch (error) {
      console.error("Error accessing devices or permissions denied:", error);
      setErrorMessage(
        "Microphone access denied. Please enable microphone permissions."
      );
    }
  };

  // Start recording using the digital microphone (or default if not available)
  const startRecording = () => {
    const audioConstraints = digitalMicDeviceId
      ? { audio: { deviceId: digitalMicDeviceId } }
      : { audio: true }; // Fallback to default microphone if digital mic is not found

    navigator.mediaDevices
      .getUserMedia(audioConstraints)
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();

        mediaRecorder.ondataavailable = (e) => {
          setAudioBlob(e.data);
        };

        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setErrorMessage(
          "Failed to start recording. Please check microphone permissions."
        );
      });
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  const handleSubmit = async () => {
    if (audioBlob) {
      const formData = new FormData();
      formData.append("audio_file", audioBlob, "recorded_audio.wav");

      try {
        const response = await axios.post(
          "http://localhost:8000/upload-audio/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(response.data);
        setUserSpeech(response.data.res);
      } catch (error) {
        console.error("Error:", error); // Handle error
        setErrorMessage("Failed to upload audio. Please try again.");
      }
    }
  };

  // Fetch microphone devices on component mount
  useEffect(() => {
    getDigitalMicrophone();
  }, []);

  return (
    <div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {isRecording ? (
        <button onClick={stopRecording}>Stop Recording</button>
      ) : (
        <button onClick={startRecording}>Start Recording</button>
      )}
      <br />
      <button onClick={handleSubmit} disabled={!audioBlob}>
        Submit Audio
      </button>
      <br />
      <br />
      <h1>User Speech</h1>
      <br />
      <p>{userSpeech}</p>
    </div>
  );
}

export default AudioRecorder;
