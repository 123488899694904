import React, { useState } from "react";
// import Cookies from "universal-cookie";
const NavButtons = ({
  img,
  buttonName,
  hoverImg,
  active,
  setAuth,
  handleLogout,
  setDay,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  function handleClick() {
    if (setDay) {
      setDay(null);
    }
  }
  return (
    <div
      onClick={handleLogout}
      className={`${
        active && `bg-black text-white font-medium`
      } hover:bg-black hover:text-white hover:font-medium cursor-pointer
     text-lg py-[12px] 5xl:px-[24px] 4xl:px-[12px] 3xl:px-[24px] ss:px-[24px] px-[24px] rounded-[12px] 5xl:mx-[48px] 4xl:mx-[28px] 3xl:mx-[18px] ss:mx-[16px] mx-[16px] mb-2`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center" onClick={() => handleClick()}>
        <img src={isHovered || active ? hoverImg : img} alt="img" />

        <div className="mx-[14px] 4xl:mx-[8px] 5xl:text-[18px] 4xl:text-[16px] 3xl:text-[13.5px] ss:text-sm text-sm">
          {buttonName}
        </div>
      </div>
    </div>
  );
};

export default NavButtons;
