import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DropdownArrow from "../../Assets/Svgs/DropdownArrow.svg";

const FilterBy = [
  { id: 0, UI: "Today", value: "today" },
  { id: 1, UI: "Yesterday", value: "yesterday" },
  { id: 2, UI: "Last Week", value: "last_week" },
  { id: 3, UI: "Last Month", value: "last_month" },
  { id: 4, UI: "Last Year", value: "last_year" },
  { id: 5, UI: "Till Date", value: "all_time" },
];

const TestData = () => {
  const [testSummary, setTestsummary] = useState("");
  const [filter, setFilter] = useState({ value: "all_time", UI: "Till Date" });
  const [isOpen, setIsOpen] = useState(false); //for dropdown modal
  const dropdownRef = useRef(null);
  //function for setting filter value and change UI
  function changeFilter(value, UI) {
    setFilter((prev) => ({ ...prev, value: value, UI: UI }));
  }

  const Options = FilterBy.map((el, i) => {
    return (
      <div
        key={i}
        className="hover:bg-[#ACD4F7] py-[6px] px-[24px] cursor-pointer transition-all"
        onClick={() => changeFilter(el.value, el.UI)}
      >
        {el.UI}
      </div>
    );
  });

  function handleEscapeKey(event) {
    if (event.key === "Escape") {
      // Your code to handle the escape key press goes here
      setIsOpen(false);
    }
  }
  document.addEventListener("keydown", handleEscapeKey);
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const apiKey = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const testSummary = async () => {
      try {
        const res = await axios.get(`${apiKey}/user_assessments_summary`, {
          params: { time_range: filter.value },
          withCredentials: true,
        });
        // console.log(res);
        setTestsummary(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    testSummary();
  }, [filter]);

  const summaryItems = [
    { label: "Total Tests Taken", value: testSummary.total_tests ?? 0 },
    { label: "Average Score", value: `${testSummary.average_score ?? 0}%` },
    { label: "Words Mastered", value: testSummary.words_mastered ?? 0 },
    { label: "Phrases Mastered", value: testSummary.phrases_mastered ?? 0 },
  ];

  return (
    <>
      <div className="flex justify-between">
        <div>
          <p
            className="font-bold text-[22px] 3xl:text-[25px] 4xl:text-[28px] 5xl:text-[32px]"
            style={{ fontFamily: "Cardo" }}
          >
            Test Dashboard
          </p>
          <p
            className="font-normal text-xs 3xl:text-[13px] 4xl:text-[15px] 5xl:text-[19px] text-[#2E2E30] mt-[2px] 3xl:mt-[8px]"
            style={{ fontFamily: "lato" }}
          >
            Here’s your overall test performance on Glibzter
          </p>
        </div>
        {/* filter  */}
        <div
          className="relative cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
          ref={dropdownRef}
        >
          <section className="border rounded-[12px] py-[12px] px-[24px] flex items-center">
            <div className="w-[100px] leading-none">{filter.UI}</div>
            <div>
              <img src={DropdownArrow} alt="arrow" />
            </div>
          </section>
          {isOpen && (
            <section className="absolute border rounded-[12px] shadow-lg   py-[12px] mt-2 w-full bg-white ">
              {Options}
            </section>
          )}
        </div>
      </div>

      {/* -------------------------------------------------------------------------------------- */}
      <div className="flex justify-between mt-[30px] 3xl:mt-[34px] 4xl:mt-[34px] 5xl:mt-[38px] gap-[16px] 3xl:gap-[18px]">
        {summaryItems.map((item, index) => (
          <div
            key={index}
            className={`w-full h-[96px] 3xl:h-[131px] 4xl:h-[144px] ${
              index === 0 ? "bg-[#FFF5EB]" : "bg-white"
            } border border-[#AA7C485C] rounded-lg flex flex-col justify-center items-center `}
          >
            <div
              className="text-center font-semibold text-[#2E2E30] text-[15px] 3xl:text-[16px] 4xl:text-[18px] 5xl:text-[22px] mt-[16px]"
              style={{ fontFamily: "lato" }}
            >
              {item.label}
            </div>
            <div
              className="text-center text-[#AA7C48] text-[42px] 3xl:text-[46px] 4xl:text-[51px] 5xl:text-[62px] font-bold"
              style={{ fontFamily: "Cardo" }}
            >
              {item.value}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TestData;
