import React from "react";

const PracticeButton = () => {
  return (
    <div
      className="text-[#AA7C48] bg-[#FBF9F8] border-[1px] rounded-[12px] 5xl:py-[12px] 4xl:py-[9px] 3xl:py-[9px] ss:py-[8px] py-[8px] 5xl:px-[24px] 4xl:px-[19px] 3xl:px-[18px] ss:px-[16px] px-[16px] cursor-pointer
       group-hover:bg-[#AA7C48] group-hover:text-white group-hover:border-transparent 5xl:text-[18px] 4xl:text-[14px] 3xl:text-[13.5px] ss:text-[12px] text-[12px]"
    >
      Start Practice
    </div>
  );
};

export default PracticeButton;
