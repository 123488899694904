import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import UserPerformance from "../Components/Dashboard/Performance";
// import Chart from "../Components/Dashboard/Chart";
// import Graph from "../Components/Dashboard/Graph";
import { useFetch } from "../Hooks/useFetch";
import Source from "../Components/Dashboard/Source";
// import WordList from "../Components/Dashboard/WordList/WordList";
import axios from "axios";
import { useNavigate } from "react-router";
import { useUseContext } from "../Hooks/useUseContext";
let initialState = {
  words: true,
  phrases: false,
  practisedWords: false,
  skippedWords: false,
  practisedPhrases: false,
  skippedPhrases: false,
  selected: true,
};
const Dashboard = () => {
  const navigate = useNavigate();
  const { setUserInfo } = useUseContext();
  // console.log(userInfoRef);
  //state
  const [select, setSelect] = useState(initialState); //for filtering dashboard page based on user creds

  //for scraped data
  const apiKey = process.env.REACT_APP_API_URL;
  const { data } = useFetch({
    URL: `${apiKey}/metrics`,
    fetchId: "data",
  });
  // console.log(data);
  //for scraped data timeline
  const { dataByTime } = useFetch({
    URL: `${apiKey}/time_based_metrics`,
    fetchId: "dataByTime",
  });
  console.log(dataByTime, "byTime");

  //effects
  //for getting user info
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/user_info`, {
          withCredentials: true,
        });
        setUserInfo(response.data);
      } catch (error) {
        handleRemoveCookies();
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div className="flex">
      <div className="sticky top-0 h-screen ">
        <Sidebar />
      </div>

      <div className=" grow px-[48px] pb-[48px] bg-[#FBF9F8] min-h-screen h-full">
        <section className="flex justify-end mt-[20px]">
          <Source />
        </section>
        <main className="mt-[70px]">
          <UserPerformance
            select={select}
            setSelect={setSelect}
            data={data}
            dataByTime={dataByTime}
          />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
