import TickIcon from "../../Assets/Svgs/TickIcon.svg";
import TickIconWhite from "../../Assets/Svgs/TickIconWhite.svg";
import Icon from "../../Assets/Icon/ic-logo-glibz.svg";
import axios from "axios";
import "./Plan.css";
import CurrentPlan from "./CurrentPlan";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
const plans = [
  {
    id: 0,
    plan: "Starter Plan",
    // package: "Free",
    // paymentPack: false,
    packageINR: "Free",
    packageUSD: "Free",
    paymentPackINR: false,
    paymentPackUSD: false,
    time: "Free",
    img: TickIcon,
    img_white: TickIconWhite,
    validity1: "10 online articles per day",
    validity2: "5 YouTube videos per day",
    validity3: "1 Netflix video per day",
    limit:
      "*Subtitles of YouTube Videos with more than 50,000 words will not be extracted.",
  },
  {
    id: 1,
    plan: "Weekly Plan",
    // package: "₹99/week",
    // paymentPack: 99,
    packageINR: "₹99/week",
    packageUSD: "$1/week",
    paymentPackINR: 99,
    paymentPackUSD: 1,
    time: "week",
    img: TickIcon,
    img_white: TickIconWhite,
    validity1: "7 days",
    validity2: "Unlimited online articles",
    validity3: "Unlimited videos",
    limit:
      "*Subtitles of YouTube Videos with more than 50,000 words will not be extracted.",
  },
  {
    id: 2,
    plan: "Monthly Plan",
    // package: "₹299/month",
    // paymentPack: 299,
    packageINR: "₹299/month",
    packageUSD: "$4/month",
    paymentPackINR: 299,
    paymentPackUSD: 4,
    time: "month",
    img: TickIcon,
    img_white: TickIconWhite,
    validity1: "30 days",
    validity2: "Unlimited online articles",
    validity3: "Unlimited videos",
    limit:
      "*Subtitles of YouTube Videos with more than 50,000 words will not be extracted.",
  },
  {
    id: 3,
    plan: "Annual Plan",
    // package: "₹2999/annum",
    // paymentPack: 2999,
    packageINR: "₹2999/annum",
    packageUSD: "$40/annum",
    paymentPackINR: 2999,
    paymentPackUSD: 40,
    time: "annual",
    img: TickIcon,
    img_white: TickIconWhite,
    validity1: "365 days",
    validity2: "Unlimited online articles ",
    validity3: "Unlimited videos",
    limit:
      "*Subtitles of YouTube Videos with more than 50,000 words will not be extracted.",
  },
];

const Pricings = ({ premium, setRerender }) => {
  const apiKey = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [user, setUser] = useState({ email: "", contact_number: "" });
  const [loading, setLoading] = useState(true);

  // console.log(user, "user");
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiKey}/get_user_details`, {
          withCredentials: true,
        });
        // console.log(response.data, "---res---");
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchData();
  }, []);
  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //function will get called when clicked on the pay button.
  async function displayRazorpayPaymentSdk(price, time) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }

    // creating a new order and sending order ID to backend
    const result = await axios.get(
      `${apiKey}/razorpay_order?time_range=${time}&currency=${changeCurrency}`,
      {
        withCredentials: true,
      }
    );

    if (!result) {
      alert("Server error. please check are you online?");
      return;
    }

    // console.log(result.data);
    // Getting the order details back
    const {
      data: { name, merchantId, amount, currency, orderId },
    } = result.data;

    const options = {
      key: merchantId,
      amount: amount.toString(),
      currency: currency,
      name: "Glibzter",
      description: "Transaction",
      image: Icon,
      order_id: orderId,
      handler: async function (response) {
        const data = response;

        // console.log(data)
        const result = await axios.post(`${apiKey}/razorpay_callback`, data, {
          withCredentials: true,
        });

        setRerender(result.data.status);
      },
      redirect: false,
      prefill: {
        name: name,
        email: user.email,
        contact: user.contact_number,
      },
      notes: {
        address: "None",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  //for not calling for free function
  function handleClick(paymentPack, time) {
    // console.log(paymentPack, "------------------");
    if (paymentPack) displayRazorpayPaymentSdk(paymentPack, time);
  }
  // console.log(premium, "premium");

  const [changeCurrency, setChangeCurrency] = useState("INR");
  return (
    <main className="">
      {premium.status !== "Inactive" && !loading && (
        <CurrentPlan premium={premium} />
      )}
      <div className="flex gap-2 pb-4">
        <div className="text-[#2E2E30] 5xl:text-[14px] 4xl:text-[12px] 3xl:text-[12px] ss:text-[10px] text-[10px] my-auto">
          Choose Currency
        </div>
        <select
          value={changeCurrency}
          onChange={(e) => setChangeCurrency(e.target.value)}
          className="bg-transparent border border-[#000000] rounded-lg focus:outline-none text-center p-1 5xl:text-[14px] 4xl:text-[12px] 3xl:text-[11px] ss:text-[10px] text-[10px]"
        >
          <option
            value="INR"
            className="5xl:text-[14px] 4xl:text-[12px] 3xl:text-[11px] ss:text-[10px] text-[10px]"
          >
            INR
          </option>
          <option
            value="USD"
            className="5xl:text-[14px] 4xl:text-[12px] 3xl:text-[11px] ss:text-[10px] text-[10px]"
          >
            USD
          </option>
        </select>
      </div>

      <div className="flex justify-between 5xl:gap-[24px] 4xl:gap-[24px] 3xl:gap-[24px] ss:gap-[8px]">
        {plans.map((el, id) => {
          let allowPayments = premium.status === "Inactive";
          // console.log(premium, "allow");
          let currentPack =
            (el.time === premium.plan && premium.status === "Active") ||
            (el.paymentPackINR === false && premium.status === "Inactive");

          const pricePack =
            changeCurrency === "INR" ? el.paymentPackINR : el.paymentPackUSD;
          const packageLabel =
            changeCurrency === "INR" ? el.packageINR : el.packageUSD;

          return (
            <div
              className={`p-[16px] w-[200px] rounded-[12px] ${
                currentPack ? "bg-black text-white" : "bg-[#FFF9F2]"
              } `}
              key={id}
            >
              <h3 className="5xl:text-[18px] 4xl:text-[14px] 3xl:text-[13.5px] ss:text-[12px] text-[12px] text-center ">
                {el.plan}
              </h3>
              <h1 className="5xl:text-[24px] 4xl:text-[19px] 3xl:text-[18px] ss:text-[16px] text-[16px] font-bold font-[Cardo] 5xl:mb-[24px] 4xl:mb-[19px] 3xl:mb-[18px] ss:mb-[16px] mb-[16px] text-center">
                {/* {el.package} */} {packageLabel}
              </h1>
              <section className="text-[10px] flex flex-col gap-[12px]">
                <div className="flex  items-center gap-[6px]">
                  {currentPack ? (
                    <img src={el.img_white} alt="img" />
                  ) : (
                    <img src={el.img} alt="img" />
                  )}
                  <p>{el.validity1}</p>
                </div>
                <div className="flex items-center gap-[6px] ">
                  {currentPack ? (
                    <img src={el.img_white} alt="img" />
                  ) : (
                    <img src={el.img} alt="img" />
                  )}
                  <p>{el.validity2}</p>
                </div>
                <div className="flex items-center gap-[6px]">
                  {currentPack ? (
                    <img src={el.img_white} alt="img" />
                  ) : (
                    <img src={el.img} alt="img" />
                  )}
                  <p>{el.validity3}</p>
                </div>
              </section>
              {currentPack ? (
                <Link to="/dashboard">
                  <div
                    className={`bg-[#FBF9F8] cursor-pointer  hover:bg-[#aa7c48] hover:text-white  button   
                                 5xl:px-[24px] 4xl:px-[24px] 3xl:px-[10px] ss:px-[0px] py-[12px] rounded-[12px] my-[24px] text-center border-1 leading-none text-[14px]`}
                  >
                    Start Learning
                  </div>
                </Link>
              ) : (
                <div
                  onClick={() =>
                    allowPayments &&
                    handleClick(pricePack, el.time, premium.plan)
                  }
                  className={`bg-[#FBF9F8] cursor-pointer  hover:bg-[#aa7c48] hover:text-white 
                  5xl:px-[24px] 4xl:px-[24px] 3xl:px-[10px] ss:px-[0px] py-[12px] rounded-[12px] my-[24px] text-center border-1 leading-none text-[14px]`}
                >
                  Subscribe
                </div>
              )}

              <section
                className={`${
                  currentPack ? "text-white" : "text-[#2E2E3080]"
                } text-[10px] mt-[20px] `}
              >
                {el.limit}
              </section>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default Pricings;
