import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import logo from "../../Assets/Svgs/GlibzterLogo-transparent.svg";
import backArrow from "../../Assets/Svgs/BackArrow.svg";
import Words from "../PracticeWords/PracticeSession/Words";
import Chart from "react-apexcharts";
import Instructions from "../PracticeWords/PracticeSession/Instructions";

const DisplayFiveWord = () => {
  const [wordsData, setWordData] = useState([]);
  const [essential, setEssential] = useState("");
  const [uncategorized, setUncategorized] = useState("");
  const isNext = true;
  const isBasic = false;

  const location = useLocation();
  const { section_id } = location.state || {};
  // console.log(section_id, "---------section id----------------");
  const redirect = location.pathname.replace(/^\//, "");
  const apiKey = process.env.REACT_APP_API_URL;
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiKey}/get-todays-sectionwise-words/${section_id}?text_type=word`,
        {
          withCredentials: true,
        }
      );
      // console.log(response, "$$$$$$$$$$$$$$$$$$$$$$$");
      if (response.data) {
        setWordData(response.data.words);
        setEssential(Number(response.data.essential_words));
        setUncategorized(Number(response.data.uncategorized_words));
        // console.log(wordsData, "---------word data---------");
        // console.log(
        //   essential,
        //   uncategorized,
        //   "-----%%%%%%%555--------------------"
        // );
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Initial fetch on mount
  useEffect(() => {
    fetchData();
  }, []);

  let skipped = wordsData.map((el) => {
    return { text: el.word, status: "skipped" };
  });
  const handleSkipAll = async () => {
    if (skipped) {
      try {
        //api for practised words
        await axios.post(`${apiKey}/mark_practised_text`, skipped, {
          withCredentials: true,
        });
        // console.log("Post successful:");
        fetchData();
      } catch (error) {
        if (error.res && error.res.status === 401) {
          // navigate("/");
          console.log(error);
        }
      }
    }
  };
  return (
    <>
      <div className="flex">
        <div className="sticky top-0 h-screen">
          <Sidebar />
        </div>
        <div className="grow px-[38px] 4xl:px-[138px] pb-[48px] bg-[#fbf9f8] overflow-y-auto ">
          <main className="px-12 pt-12 mx-auto">
            <img src={logo} alt="logo" />
            <section className="flex justify-between pt-[48px] ">
              <div className="flex items-center">
                {" "}
                <Link to="/home">
                  <img
                    className=" cursor-pointer"
                    src={backArrow}
                    alt="arrow"
                  />
                </Link>
                <p className="ms-[12px] 5xl:text-[30px] 4xl:text-[24px] 3xl:text-[22px] ss:text-[20px] text-[20px] font-[Cardo] font-bold">
                  Click to practice any word
                </p>
              </div>
            </section>

            <section className="flex 5xl:gap-[48px] 4xl:gap-[39px] 3xl:gap-[36px] ss:gap-[32px] gap-[30px] py-[48px] justify-between">
              <div>
                <div className="bg-white rounded-[8px] flex justify-center items-center py-[16px] ">
                  {essential + uncategorized > 0 ? (
                    <Chart
                      type="donut"
                      width={290}
                      height={290}
                      series={[essential, uncategorized]}
                      options={{
                        labels: ["Essential", "Uncategorized"],
                        colors: ["#AA7C48", "#808080"],
                        dataLabels: {
                          enabled: true,
                          formatter: (val) => {
                            return val + "%";
                          },
                          style: {
                            fontSize: "16px",
                            fontWeight: "bold",
                          },
                        },
                        plotOptions: {
                          pie: {
                            expandOnClick: false,
                            donut: {
                              size: "50%",
                              background: "transparent",
                              labels: {
                                show: true,
                                name: {
                                  show: true,
                                  offsetY: 0,
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#2E2E30",
                                },
                                value: {
                                  show: false,
                                },
                                total: {
                                  show: true,
                                  label: "Words Aquired",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                },
                              },
                            },
                          },
                        },
                        stroke: {
                          width: 2.5,
                          colors: ["#fff"],
                        },
                        legend: {
                          show: true,
                          position: "bottom",
                          labels: {
                            colors: ["#AA7C48", "#808080"],
                            useSeriesColors: true,
                          },
                        },
                        chart: {
                          type: "donut",
                        },
                      }}
                    />
                  ) : (
                    <div className="h-[145px] flex justify-center items-center">
                      No results
                    </div>
                  )}
                </div>

                {/* instructions for practice words */}
                <section className="5xl:mt-[48px] 4xl:mt-[39px] 3xl:mt-[36px] ss:mt-[32px] mt-[32px]">
                  <Instructions textSingular="word" textPlural="words" />
                </section>
              </div>
              <div className="w-full">
                <Words
                  practiceText="Click to practice any word"
                  data={wordsData}
                  gptRedirect={"word"}
                  isNext={isNext}
                  isBasic={isBasic}
                  redirect={redirect}
                  handleSkipAll={handleSkipAll}
                />
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default DisplayFiveWord;
