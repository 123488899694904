import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Source from "../Dashboard/Source";
import Assessment from "../../Assets/Svgs/Assessment.svg";
import TestPoints from "../../Assets/Svgs/TestPoints.svg";
import warning from "../../Assets/Svgs/notifi.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Assets/Gif/Loader.gif";
import TestData from "./TestData";

const TestDashboard = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  const apiKey = process.env.REACT_APP_API_URL;

  const notify = () =>
    toast(
      <div className="flex justify-between w-full h-full">
        <div className="flex grow gap-4 3xl:gap-5">
          <img src={warning} alt="warning" className="w-[70px]" />
          <div>
            <p
              className="text-[#2E2E30] font-bold text-base 3xl:text-lg"
              style={{ fontFamily: "lato" }}
            >
              Oops! Practice Required.
            </p>
            <p
              className="text-xs 3xl:text-sm font-bold text-[#757575] mt-1"
              style={{ fontFamily: "lato" }}
            >
              You need to practice atleast
              <span className="text-[#2E2E30]">
                {" "}
                10 words or phrases <br />
              </span>{" "}
              before taking the test.
            </p>
          </div>
        </div>
        <div className="my-auto mr-[10px] 3xl:mr-0">
          <button
            className="h-[35px] 4xl:h-[40px] bg-[#AA7C48] text-white text-[10px] 3xl:text-xs rounded-[7px] font-semibold  px-[22px] "
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Let's Practice
          </button>
        </div>
      </div>
    );

  const handleStartTest = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiKey}/assessment/create-assessment/`,
        {},
        {
          withCredentials: true,
        }
      );
      // console.log(response.data);
      if (response.data) {
        sessionStorage.setItem("testId", response.data.id);
        // console.log(response.data.id, "-------------------");
        setTimeout(() => {
          setLoading(false);
          navigate("/test");
        }, 100);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 400) {
        notify();
        console.log(error);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        hideProgressBar="true"
        style={{
          width: "600px",
        }}
        toastStyle={{
          border: "2px solid #E0CFBD",
          borderRadius: "12px",
        }}
      />
      <div className="flex">
        <div className="sticky top-0 h-screen ">
          <Sidebar />
        </div>

        <div className=" grow px-[48px] pb-[48px] bg-[#FBF9F8] min-h-screen h-full">
          <section className="flex justify-end mt-[20px]">
            <Source />
          </section>
          <main className="w-full h-full mt-[44px] 3xl:mt-[50px] 4xl:mt-[55px] 5xl:mt-[70px]">
            <TestData />
            {/* ---------------------------------------------------------------------------------------------------------------------------------------------- */}
            <div className="flex mt-[35px] 3xl:mt-[40px] 4xl:mt-[45px] 5xl:mt-[50px] justify-between">
              <div className="flex flex-col gap-[10px] 4xl:gap-[12px] 5xl:gap-[16px]">
                <p
                  className="font-bold text-[22px] 3xl:text-[24px] 4xl:text-[27px] 5xl:text-[32px] mb-[8px] 5xl:mb-[4px]"
                  style={{ fontFamily: "Cardo" }}
                >
                  Test Instructions
                </p>
                <div className="flex gap-2">
                  <img
                    alt="points"
                    src={TestPoints}
                    className="w-[15px] h-[19px] mt-1 5xl:mt-2"
                  />{" "}
                  <div
                    className="font-normal text-[16px] 3xl:text-[17px] 4xl:text-[19px] 5xl:text-[20px] text-[#717171] "
                    style={{ fontFamily: "lato" }}
                  >
                    This test consists of two parts:{" "}
                    <span className="font-bold text-[#4A4A4A]">
                      Multiple Choice Questions
                    </span>
                    (MCQ) and{" "}
                    <span className="font-bold text-[#4A4A4A]">
                      Speech / Writing Practice
                    </span>
                    .
                  </div>
                </div>
                <div className="flex gap-2">
                  <img
                    alt="points"
                    src={TestPoints}
                    className="w-[15px] h-[19px] mt-1 5xl:mt-2"
                  />{" "}
                  <div
                    className="font-normal text-[16px] 3xl:text-[17px] 4xl:text-[19px] 5xl:text-[20px] text-[#717171]"
                    style={{ fontFamily: "lato" }}
                  >
                    Each section includes{" "}
                    <span className="font-bold text-[#4A4A4A]">
                      5 questions, each worth 5 marks
                    </span>
                    .
                  </div>
                </div>
                <div className="flex gap-2">
                  <img
                    alt="points"
                    src={TestPoints}
                    className="w-[15px] h-[19px] mt-1 5xl:mt-2"
                  />{" "}
                  <div
                    className="font-normal text-[16px] 3xl:text-[17px] 4xl:text-[19px] 5xl:text-[20px] text-[#717171]"
                    style={{ fontFamily: "lato" }}
                  >
                    There is{" "}
                    <span className="font-bold text-[#4A4A4A]">
                      no time limit
                    </span>
                    , so take your time to think carefully about each question.
                  </div>
                </div>
                <div className="flex gap-2">
                  <img
                    alt="points"
                    src={TestPoints}
                    className="w-[15px] h-[19px] mt-1 5xl:mt-2"
                  />{" "}
                  <div
                    className="font-normal text-[16px] 3xl:text-[17px] 4xl:text-[19px] 5xl:text-[20px] text-[#717171]"
                    style={{ fontFamily: "lato" }}
                  >
                    For the MCQ section, select the best answer from the options
                    provided.
                  </div>
                </div>
                <div className="flex gap-2">
                  <img
                    alt="points"
                    src={TestPoints}
                    className="w-[15px] h-[19px] mt-1 5xl:mt-2"
                  />{" "}
                  <div
                    className="font-normal text-[16px] 3xl:text-[17px] 4xl:text-[19px] 5xl:text-[20px] text-[#717171]"
                    style={{ fontFamily: "lato" }}
                  >
                    The Speech / Writing Practice section enables you to either
                    record your voice response to the question or your written
                    response for evaluation.
                  </div>
                </div>
                <div className="flex gap-2">
                  <img
                    alt="points"
                    src={TestPoints}
                    className="w-[15px] h-[19px] mt-1 5xl:mt-2"
                  />{" "}
                  <div
                    className="font-normal text-[16px] 3xl:text-[17px] 4xl:text-[19px] 5xl:text-[20px] text-[#717171]"
                    style={{ fontFamily: "lato" }}
                  >
                    If you record a response through voice and you see an error
                    in the textual version, you can edit the same by typing.
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={Assessment}
                  alt="Test"
                  className="h-[310px] 4xl:h-[330px] 5xl:h-[380px] 5xl:w-[400px]"
                />
              </div>
            </div>
            {/* ---------------------------------------------------------------------------------------------------------------------------------------- */}
            <button
              onClick={handleStartTest}
              className="bg-[#009883] hover:bg-gradient-to-b hover:from-[#009883] hover:to-[#0E6155] text-[#FFFFFF] mt-[10px] 3xl:mt-0 4xl:mt-[5px] w-[212px] 3xl:w-[237px] 4xl:w-[263px] 5xl:w-[318px] h-[44px] 3xl:h-[48px] 4xl:h-[54px] 5xl:h-[65px] text-[16px] 3xl:text-[17px] 4xl:text-[19px] 5xl:text-[23px] font-bold rounded-lg"
            >
              START TEST
            </button>
          </main>
        </div>
        {Loading && (
          <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
            <img src={Loader} alt="Loading..." className="w-24 h-24" />
          </div>
        )}
      </div>
    </>
  );
};

export default TestDashboard;
