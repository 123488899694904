import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import warning from "../../Assets/Svgs/notifi.svg";
import celebrateGreen from "../../Assets/Svgs/CelebrateGreen.svg";
import sorrow from "../../Assets/Svgs/sorrow.svg";
import disclaimer from "../../Assets/Svgs/disclaimer.svg";
// import Result from "./Result";

const McqTest = ({ questions, onTestComplete }) => {
  const navigate = useNavigate();
  const testId = sessionStorage.getItem("testId");
  const apiKey = process.env.REACT_APP_API_URL;
  const [data, setData] = useState(false);
  const [quit, setQuit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [ansStatus, setAnsStatus] = useState("");
  const [ansExplanation, setAnsExplanation] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  // const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  // const [isResult, setIsResult] = useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const savedIndex = parseInt(
      sessionStorage.getItem("mcqCurrentQuestionIndex"),
      10
    );
    return isNaN(savedIndex) ? 0 : savedIndex;
  });

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    sessionStorage.setItem("mcqCurrentQuestionIndex", currentQuestionIndex);
    if (currentQuestionIndex >= questions.length) {
      setCurrentQuestionIndex(0);
    }
  }, [currentQuestionIndex, questions.length]);

  const currentQuestion = questions[currentQuestionIndex];

  const handleOptionSelect = (optionKey) => {
    // if (!isAnswerSubmitted) {
    setSelectedOption(optionKey);
    // }
  };

  const handleQuit = async () => {
    try {
      const quit = await axios.post(
        `${apiKey}/assessment/quit-assessment/`,
        {
          assessment_id: testId,
        },
        {
          withCredentials: true,
        }
      );
      if (sessionStorage.getItem("testId")) {
        sessionStorage.removeItem("testId");
      }
      if (sessionStorage.getItem("isMcqComplete")) {
        sessionStorage.removeItem("isMcqComplete");
      }
      if (sessionStorage.getItem("isSpeechComplete")) {
        sessionStorage.removeItem("isSpeechComplete");
      }
      if (sessionStorage.getItem("testType")) {
        sessionStorage.removeItem("testType");
      }
      if (sessionStorage.getItem("mcqCurrentQuestionIndex")) {
        sessionStorage.removeItem("mcqCurrentQuestionIndex");
      }
      if (sessionStorage.getItem("speechCurrentQuestionIndex")) {
        sessionStorage.removeItem("speechCurrentQuestionIndex");
      }
      // setIsResult(true);
      navigate("/test-dashboard");
      // console.log(quit.data);
    } catch (e) {
      console.log(e);
    }
  };

  const submitAnswer = async () => {
    setIsSubmitDisabled(false);
    const answerPayload = {
      assessment_id: testId,
      question_id: currentQuestion.id,
      user_option: selectedOption,
    };

    try {
      const response = await axios.post(
        `${apiKey}/assessment/user-mcq-answer/`,
        answerPayload,
        {
          withCredentials: true,
        }
      );
      // console.log(response);
      if (response.data) {
        setData(true);
        setIsSubmit(true);
        setAnsStatus(response.data.status);
        setAnsExplanation(response.data.explanation);
        // setIsAnswerSubmitted(true);
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };

  // Function for moving to the next question
  const moveToNextQuestion = () => {
    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < questions.length) {
      setCurrentQuestionIndex(nextIndex);
      setSelectedOption("");
      setIsSubmit(false);
    } else {
      sessionStorage.removeItem("mcqCurrentQuestionIndex");
      if (onTestComplete) {
        onTestComplete();
      }
    }
  };

  const handleNext = async () => {
    setData("");
    setAnsStatus("");
    setAnsExplanation("");
    setIsSubmitDisabled(true);
    if (!selectedOption) return;
    moveToNextQuestion();
  };

  return (
    <>
      {/* {isResult ? (
        <Result />
      ) : ( */}
      <div>
        <div className="flex justify-between">
          <div
            className="font-bold text-[#2E2E30] text-2xl 3xl:text-[26px]"
            style={{ fontFamily: "cardo" }}
          >
            Section 1: Pick the Right Answer
          </div>
          <button
            className="border-[2px] border-[#009883] rounded-md w-[112px] h-[40px] 3xl:w-[124px] 3xl:h-[48px] text-base 3xl:text-[17px] text-[#009883]"
            style={{ fontFamily: "lato" }}
            onClick={() => {
              setQuit(true);
            }}
          >
            Quit
          </button>
        </div>

        {/* Progress Bar */}
        <div className="flex justify-center my-5">
          {questions.map((_, index) => (
            <div
              key={index}
              className={`flex-1 h-2 mx-1 rounded ${
                index <= currentQuestionIndex ? "bg-[#009883]" : "bg-[#FBF9F8]"
              } h-[8px] rounded-[40px] mx-1`}
            ></div>
          ))}
        </div>

        {/* Question */}
        <div className="text-lg font-semibold " style={{ fontFamily: "lato" }}>
          Question {currentQuestionIndex + 1}: {currentQuestion.question}
        </div>

        {/* Options */}
        <div className="flex flex-col gap-2 mt-[28px]">
          {Object.entries(currentQuestion.options).map(([key, value]) => (
            <div
              key={key}
              onClick={() => handleOptionSelect(key)}
              className={` border cursor-pointer transition-colors duration-200 ${
                selectedOption === key
                  ? "bg-[#009883] text-white"
                  : "text-[#595959]"
              } text-left border-[2px] border-[#EAEAEA] rounded-[100px] text-lg  px-[20px] py-[10px]`}
            >
              <span className="font-semibold">{key}.</span> {value}
            </div>
          ))}
        </div>
        {data && (
          <div className="">
            {ansStatus === "correct" ? (
              <>
                <div className="mt-[36px] border border-[#E0E0E0] rounded-[12px] bg-[#F8F8F8] py-[20px] px-[24px]">
                  <div className="flex gap-[8px]">
                    <div
                      className="font-bold text-2xl text-[#009883]"
                      style={{ fontFamily: "cardo" }}
                    >
                      Correct Response!
                    </div>
                    <img
                      src={celebrateGreen}
                      alt="celebrate"
                      className="w-[26px] h-[26px]"
                    />
                  </div>
                  <div className="mt-[16px] text-base text-[#828282] leading-5">
                    {ansExplanation}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mt-[36px] border border-[#E0E0E0] rounded-[12px] py-[20px] px-[24px]">
                  <div className="flex gap-[8px]">
                    <div
                      className="text-[#DB3D46] font-bold text-2xl"
                      style={{ fontFamily: "cardo" }}
                    >
                      Incorrect Response!
                    </div>

                    <img src={sorrow} alt="" />
                  </div>
                  <div className="bg-[#F5F5F5] rounded-[12px] my-[24px]">
                    <div className="text-center pt-[24px] font-bold text-xl">
                      Correct response:{" "}
                    </div>
                    <div className="text-center text-[#828282] px-[24px] mt-[12px] pb-[24px] leading-5">
                      {ansExplanation}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {/* DISCLAIMER  */}

        {/* Next and submit Button */}
        <div className="flex justify-end gap-[10px] mt-[50px]">
          {isSubmitDisabled ? (
            <button
              className={`w-[212px] h-[44px] 3xl:h-[48px] font-bold text-base 3xl:text-[17px] rounded-[6px] text-white ${
                selectedOption
                  ? "bg-[#009883] cursor-pointer"
                  : "bg-[#D0D0D0] cursor-not-allowed"
              }`}
              onClick={submitAnswer}
              disabled={!selectedOption}
            >
              Submit
            </button>
          ) : (
            ""
          )}

          <button
            onClick={handleNext}
            disabled={!isSubmit}
            className={`px-4 py-2 rounded ${
              isSubmit
                ? "bg-[#009883] cursor-pointer"
                : "bg-[#D0D0D0] cursor-not-allowed"
            } text-white font-bold text-base 3xl:text-[17px] w-[212px] 3xl:w-[239px] h-[44px] 3xl:h-[48px] rounded-[6px]`}
          >
            NEXT
          </button>
        </div>

        <div className="flex gap-2 mt-2">
          <img src={disclaimer} alt="disclaimer" />
          <p className="text-[#737D87] text-sm">
            For some questions, there are likely to be more than one option as
            the correct answer.
          </p>
        </div>

        {quit && (
          <div className="fixed inset-0 bg-white bg-opacity-0 flex items-center justify-center z-50">
            <div className="bg-white w-[80%] flex justify-center rounded-[16px] shadow-custom-shadow">
              <div className=" w-[60%]">
                <div className="flex justify-center">
                  <img
                    src={warning}
                    alt="warning"
                    className="w-[80px] mt-[34px] mb-[24px]"
                  />
                </div>
                <div className="font-medium text-[16px] 3xl:text-[18px] text-center 5xl:mx-[25%] mx-[15%]">
                  Are you sure you want to quit? If you do, you won’t be able to
                  resume your test.
                </div>
                <div className="mt-[24px] flex justify-between 5xl:mx-[29%] mx-[18%] 3xl:mx-[21%] 4xl:mx-[24%] mb-[34px]">
                  <button
                    className="h-[48px] bg-[#AA7C48] rounded-[12px] py-[12px] px-[64px] text-white font-semibold"
                    onClick={handleQuit}
                  >
                    Yes, Quit
                  </button>
                  <button
                    className="h-[48px] rounded-[12px] py-[12px] px-[64px] font-semibold border border-[#D0D5DD]"
                    onClick={() => {
                      setQuit(false);
                    }}
                  >
                    Resume
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* )} */}
    </>
  );
};

export default McqTest;
