import React from "react";
import backarrow from "../../Assets/Svgs/backarrow.svg";
import GlibzterLogo from "../../Assets/Svgs/GlibzterLogo.svg";
import plus from "../../Assets/Svgs/plus.svg";
import Loading from "../../Assets/Gif/Loading.gif";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import "./chat.css";
import { useScroll } from "../../Hooks/useScroll";
import userProfile from "../../Assets/Images/UserProfile.png";
import Speech from "./Speech";
import Translate from "./Translate";
// import { Context } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.speech/SpeechServiceConfig";

const ChatBot = ({
  redirect,
  data,
  wordTheme,
  setWordTheme,
  setContext,
  setWord,
  response,
  setResponse,
  isLoading,
  context,
}) => {
  const { divRef } = useScroll();
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_URL;
  const [isPremium, setIsPremium] = useState(false);
  const [context1, setContext1] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const day = queryParams.get("day");
  const to = queryParams.get("to");
  const gptredirect = queryParams.get("gptredirect");
  const WordTheme1 = ["Context", "Situation", "Synonyms", "Emotions"];

  if (gptredirect === "word") {
    WordTheme1.splice(4, 0, "Word Combinations");
  }

  const handleNewChat = () => {
    setResponse([]); //clear all responses
  };

  function handleTheme(el) {
    setWordTheme(el + ` : ` + data);
    setContext(el);
    setWord(data);
  }

  function getBackgroundColor(el) {
    switch (el) {
      case "Context":
        return "bg-[#5C6BC0]";
      case "Situation":
        return "bg-[#FF7043]";
      case "Synonyms":
        return "bg-[#66BB6A]";
      case "Emotions":
        return "bg-[#CE93D8]";
      case "Word Combinations":
        return "bg-[#F9A926]";
      default:
        return "bg-[#000000]";
    }
  }

  const Theme = WordTheme1.map((el, i) => {
    return (
      <div
        onClick={() => {
          handleTheme(el);
        }}
        className={`py-[8px] 5xl:px-[28px] 4xl:px-[28px] 3xl:px-[24px] ss:px-[14px] px-[14px] rounded-[50px] 5xl:text-[16px] 4xl:text-[14px] 3xl:text-[12px] ss:text-[12px] text-[10px] shadow-xl cursor-pointer ${getBackgroundColor(
          el
        )}`}
        key={i}
      >
        {el}
      </div>
    );
  });
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/check_plan`, {
          withCredentials: true,
        });
        setIsPremium(response.data.data.status);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      }
    };
    fetchData();
  }, []);

  let allResponse = response.map((el, i) => {
    const responseSplit = el.input.split("-");
    const word =
      responseSplit[0].charAt(0).toUpperCase() + responseSplit[0].slice(1);
    return (
      <main className="mt-[24px]" key={i}>
        <div className="p-[12px] flex border gap-4 border-blue-500 border-solid rounded-lg bg-[#5C6BC0] text-white">
          <div className="">
            <img
              src={userProfile}
              className="rounded-full object-cover h-8 w-8"
            />
          </div>
          <div className="5xl:text-base 4xl:text-base 3xl:text-sm ss:text-xs text-xs my-auto">
            {
              (responseSplit[1] === "Context" && (
                <p>
                  {gptredirect === "word"
                    ? `Contextual usage examples for the word "${word}"`
                    : `Contextual usage examples for the phrase "${word}"`}
                </p>
              )) ||
                (responseSplit[1] === "Situation" && (
                  <p className="">
                    {gptredirect === "word"
                      ? `Situations when the word "${word}" can be used`
                      : `Situations when the phrase "${word}" can be used`}
                  </p>
                )) ||
                (responseSplit[1] === "Synonyms" && (
                  <p>
                    {gptredirect === "word"
                      ? `Words that can be used in place of "${word}"`
                      : `Substitutes for the phrase "${word}"`}
                  </p>
                )) ||
                (responseSplit[1] === "Emotions" && (
                  <p>Using "{word}" in different emotions</p>
                )) ||
                (responseSplit[1] === "Word Combinations" && (
                  <p>Words that can be combined with "{word}"</p>
                ))
              // ||
              // (responseSplit[1] === "Test" && (
              //   <p>
              //     {gptredirect === "word"
              //       ? `Test yourself on the contextual usage of the word "${word}"`
              //       : `Test yourself on the contextual usage of the phrase "${word}"`}
              //   </p>
              // ))
            }
          </div>
        </div>
        <div className="bg-white rounded-[12px] p-[12px] whitespace-pre-line mt-[24px] 5xl:text-base 4xl:text-base 3xl:text-sm ss:text-xs text-xs">
          {el.output}
        </div>

        <TranslatedText output={el.output} />
        <div className="flex justify-end mt-[10px]">
          {/* <div className="text-[18px] rounded-[36px] bg-[#AA7C48] text-white p-[8px]">Translate</div> */}
        </div>
      </main>
    );
  });

  return (
    <div className="bg-[#FBF9F8] max-h-screen overflow-auto ">
      {/* <div className="justify-between items-center"> */}
      <section className="flex flex-col justify-between w-[100%] h-screen">
        <main className="">
          <section className="flex justify-between pt-4 pb-4">
            <section className="flex gap-[16px] items-center">
              {redirect === "daily-five-words" && (
                <div onClick={() => navigate(-1)}>
                  <div className="flex">
                    <img
                      src={backarrow}
                      alt="arrow"
                      className="p-2 w-[48px] h-[48px] cursor-pointer"
                    />
                    <img
                      src={GlibzterLogo}
                      alt="Glibzter logo"
                      className="w-[156px] h-[56px]"
                    />
                  </div>
                </div>
              )}
              {redirect === "daily-five-phrases" && (
                <div onClick={() => navigate(-1)}>
                  <div className="flex">
                    <img
                      src={backarrow}
                      alt="arrow"
                      className="p-2 w-[48px] h-[48px] cursor-pointer"
                    />
                    <img
                      src={GlibzterLogo}
                      alt="Glibzter logo"
                      className="w-[156px] h-[56px]"
                    />
                  </div>
                </div>
              )}
              {redirect === "day" && gptredirect === "word" && (
                <Link to={`/startLearning?day=${day}`}>
                  <div className="flex">
                    <img
                      src={backarrow}
                      alt="arrow"
                      className="p-2 w-[48px] h-[48px]"
                    />
                    <img
                      src={GlibzterLogo}
                      alt="Glibzter logo"
                      className="w-[156px] h-[56px]"
                    />
                  </div>
                </Link>
              )}
              {redirect === "day" && gptredirect === "phrase" && (
                <Link to={`/startLearnPhrases?day=${day}`}>
                  <div className="flex">
                    <img
                      src={backarrow}
                      alt="arrow"
                      className="p-2 w-[48px] h-[48px]"
                    />
                    <img
                      src={GlibzterLogo}
                      alt="Glibzter logo"
                      className="w-[156px] h-[56px]"
                    />
                  </div>
                </Link>
              )}
              {redirect === "words" && (
                <Link to="/startLearning">
                  <div className="flex">
                    <img
                      src={backarrow}
                      alt="arrow"
                      className="p-2 w-[48px] h-[48px]"
                    />
                    <img
                      src={GlibzterLogo}
                      alt="Glibzter logo"
                      className="w-[156px] h-[56px]"
                    />
                  </div>
                </Link>
              )}
              {redirect === "phrase" && (
                <Link to="/startLearnPhrases">
                  <div className="flex">
                    <img
                      src={backarrow}
                      alt="arrow"
                      className="p-2 w-[48px] h-[48px]"
                    />
                    <img
                      src={GlibzterLogo}
                      alt="Glibzter logo"
                      className="w-[156px] h-[56px]"
                    />
                  </div>
                </Link>
              )}
              {redirect === "dashboard" && (
                <Link to="/dashboard">
                  <div className="flex ">
                    <img
                      src={backarrow}
                      alt="arrow"
                      className="p-2 w-[48px] h-[48px]"
                    />
                    <img
                      src={GlibzterLogo}
                      alt="Glibzter logo"
                      className="w-[156px] h-[56px]"
                    />
                  </div>
                </Link>
              )}
            </section>

            <div className="flex gap-[8px] my-auto ">
              {/* <div
                className=" h-[40px] px-[24px] flex gap-2 border border-[#E3E3E3] rounded-lg cursor-pointer"
                onClick={handleNewChat}
              >
                <img
                  src={plus}
                  alt="add"
                  className="w-[16px] h-[16px] m-auto"
                />
                <div className="text-[16px] font-normal justify-self-center place-content-center ">
                  New Chat
                </div>
              </div> */}

              <div className="text-[12px] flex items-center h-[40px] my-auto">
                <div
                  className={`${
                    isPremium === "Inactive"
                      ? "bg-[#009883] border-transparent text-white font-normal"
                      : ""
                  } py-[12px] px-[18px]  rounded-s-[12px]
                 border `}
                >
                  Free
                </div>
                <div
                  className={`${
                    isPremium === "Active"
                      ? "bg-[#009883] border-transparent text-white"
                      : ""
                  } text-black rounded-e-[12px]  py-[12px] px-[12px] border-y border-e border-[#000000]`}
                >
                  Premium
                </div>
              </div>
            </div>
          </section>
        </main>
        <main className="">
          <div className="flex flex-col justify-between">
            <section className="h-[60vh] overflow-y-scroll max-w-full mb-8 mt-4">
              {/* <img src={userpicture} alt="hola" /> */}
              <div ref={divRef}>
                <div> {allResponse}</div>{" "}
                {isLoading && (
                  <div className="m-auto">
                    <img
                      className="w-[80px] m-auto py-3"
                      src={Loading}
                      alt="loader"
                    />
                  </div>
                )}
              </div>
            </section>
            <section className="flex flex-col justify-between">
              {" "}
              <section className="pb-4 pt-8 5xl:text-[16px] 4xl:text-[14px] 3xl:text-[13.5px] ss:text-[12px] text-xs leading-tight font-bold">
                Click on the audio button to listen to the pronunciation
                <br />
                {/* <p className="pt-2">
                  Only if “TEST” is clicked, will the word or phrase be
                  considered as Practiced
                </p> */}
              </section>
              <section className="flex items-center gap-4">
                <section className="flex justify-between items-center rounded-[12px] border px-[24px] py-[8px] grow">
                  <div className="grow w-[95%]">
                    {" "}
                    <input
                      value={data}
                      // onChange={(e) => setWordTheme(e.target.value)}
                      className="w-full bg-transparent border-[#2e2e3080] outline-none focus:ring-0 placeholder-black text-[14px] placeholder:text-[14px] leading-tight text-[#5C6BC0]"
                      type="text"
                    />
                  </div>
                </section>
                <section>
                  <Speech
                    response={response}
                    SpeechText={
                      response[response.length - 1]?.output || "please practice"
                    }
                  />
                </section>
              </section>
              <section className="flex justify-start mb-[24px] pt-[24px] gap-8 items-center">
                <div className="text-[0.875rem] leading-tight font-bold">
                  Click to learn
                </div>
                <div className="flex justify-between grow text-[#FFFFFF] ">
                  {Theme}
                </div>
              </section>
            </section>
          </div>
        </main>
      </section>
      {/* </div> */}
    </div>
  );
};

export default ChatBot;

function TranslatedText({ output }) {
  const [outputText, setoutputText] = useState("");
  return (
    <div>
      {outputText && (
        <div className="bg-white rounded-[12px] p-2 mt-2">{outputText}</div>
      )}
      <div className="flex justify-end mt-2 ">
        <Translate Text={output} setoutputText={setoutputText} />
      </div>
    </div>
  );
}
