import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import SwipeLeft from "../../Assets/Svgs/SwipeLeft.svg";
import SwipeRight from "../../Assets/Svgs/SwipeRight.svg";
import Celebrate from "../../Assets/Svgs/CelebrateGreen.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../Assets/Gif/Loader.gif";

const Result = () => {
  const navigate = useNavigate();
  const testId = sessionStorage.getItem("testId");
  const apiKey = process.env.REACT_APP_API_URL;
  const [results, setResults] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const isApiCalled = useRef(false);

  useEffect(() => {
    const handleFinishTest = async () => {
      if (testId == null || isApiCalled.current) return;
      isApiCalled.current = true;
      try {
        const response = await axios.post(
          `${apiKey}/assessment/finish-assessment/`,
          {
            assessment_id: testId,
          },
          { withCredentials: true }
        );
        // console.log("Test finished successfully:", response.data);
        setResults(response.data);
        // console.log(response.status);
        sessionStorage.removeItem("testId");
        sessionStorage.removeItem("isMcqComplete");
        sessionStorage.removeItem("isSpeechComplete");
        sessionStorage.removeItem("testType");
        if (sessionStorage.getItem("mcqCurrentQuestionIndex")) {
          sessionStorage.removeItem("mcqCurrentQuestionIndex");
        }
        if (sessionStorage.getItem("speechCurrentQuestionIndex")) {
          sessionStorage.removeItem("speechCurrentQuestionIndex");
        }
      } catch (error) {
        console.error("Failed to finish test:", error);
      }
    };

    handleFinishTest();
  }, []);

  const handleNext = () => {
    if (currentIndex < results.wrong_answers.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      {results ? (
        <div>
          <div className="flex  w-full">
            <div className="w-full   rounded-[18px] bg-white">
              <div className="mx-[4%] my-[2%]">
                <p
                  className="font-bold text-2xl"
                  style={{ fontFamily: "cardo" }}
                >
                  Your Test Scoreboard
                </p>
                <div className="flex justify-center gap-3">
                  <p
                    className={` ${
                      results.score > 25 ? "text-[#009883]" : "text-[#C62121]"
                    }  text-2xl font-bold text-center mt-[30px]`}
                    style={{ fontFamily: "cardo" }}
                  >
                    {results.score === 50
                      ? "Congratulations for a perfect score!"
                      : results.score >= 25
                      ? "Yay, you did a good job!"
                      : "Oops! Don't be discouraged; try again!"}
                  </p>{" "}
                  {results.score >= 25 ? (
                    <img
                      src={Celebrate}
                      alt="celebrate image"
                      className="mt-5"
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className={`w-full h-[76px] ${
                    results.score > 25 ? "bg-[#009883]" : "bg-[#C62121]"
                  } rounded-[200px] mt-[24px]`}
                >
                  {" "}
                  <p
                    style={{ fontFamily: "cardo" }}
                    className=" text-white font-bold text-[40px] h-full flex items-center justify-center"
                  >
                    {results.score} / 50
                  </p>
                </div>
                <div className="flex justify-between mt-[24px] gap-[16px] ">
                  <div
                    className={`border ${
                      results.mcq_score > 13
                        ? "border-[#00988375] bg-[#F6FAFA]"
                        : "border-[#DF9296] bg-[#FEF9FA]"
                    }  w-full rounded-[12px]`}
                  >
                    <div className="flex justify-between py-[18px] px-[32px]  rounded-[12px]">
                      <div className="font-semibold text-[20px] text-[#2E2E30] my-auto">
                        MCQS
                      </div>
                      <div
                        className={`font-bold text-[30px] ${
                          results.mcq_score >= 13
                            ? "text-[#009883]"
                            : "text-[#C62121]"
                        }`}
                      >
                        {results.mcq_score}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`border ${
                      results.speech_score > 13
                        ? "border-[#00988375] bg-[#F6FAFA]"
                        : "border-[#DF9296] bg-[#FEF9FA]"
                    } w-full rounded-[12px]`}
                  >
                    <div className="flex justify-between py-[18px] px-[32px]  rounded-[12px]">
                      <div className="font-semibold text-[20px] text-[#2E2E30] my-auto">
                        Speech Assessment
                      </div>
                      <div
                        className={`font-bold text-[30px] ${
                          results.speech_score >= 13
                            ? "text-[#009883]"
                            : "text-[#C62121]"
                        }`}
                      >
                        {results.speech_score}
                      </div>
                    </div>
                  </div>
                </div>
                {/* {results.wrong_answers && results.wrong_answers.length > 0 && (
                  <div className="border border-[#D3D3D3] rounded-[12px] py-[27px] px-[32px] mt-[24px]">
                    <div className="text-xl font-bold">
                      Review Incorrect Responses
                    </div>
                    <div className="border border-[#E0E0E0] rounded-[12px] bg-[#F8F8F8] mx-[81px] mt-[24px] py-[30px] px-[36px]">
                      <div className="text-center font-bold text-xl">
                        {results.wrong_answers[currentIndex].text}
                      </div>
                      <div className="font-semibold text-[16px] text-center mt-[10px]">
                        Question: {results.wrong_answers[currentIndex].question}
                      </div>
                      <div className="text-center mt-[12px] text-[#828282] font-normal text-base">
                        Ans: {results.wrong_answers[currentIndex].explanation}
                      </div>
                    </div>

                    <div className="mt-[24px] flex justify-between">
                      <div>
                        <button
                          onClick={handlePrevious}
                          disabled={currentIndex === 0}
                        >
                          <img
                            src={SwipeLeft}
                            alt="previous"
                            className={currentIndex === 0 ? "opacity-50" : ""}
                          />
                        </button>
                      </div>
                      <div className="text-[#828282] text-xl">
                        {currentIndex + 1} of {results.wrong_answers.length}
                      </div>
                      <div>
                        <button
                          onClick={handleNext}
                          disabled={
                            currentIndex === results.wrong_answers.length - 1
                          }
                        >
                          <img
                            src={SwipeRight}
                            alt="next"
                            className={
                              currentIndex === results.wrong_answers.length - 1
                                ? "opacity-50"
                                : ""
                            }
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}
                <div className="flex justify-end">
                  <button
                    className="mt-[24px] h-[44px] w-[158px] rounded-[8px] border-[2px] border-[#2E2E30]"
                    onClick={() => {
                      navigate("/test-dashboard");
                    }}
                  >
                    Back to home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[500px] flex justify-center items-center">
          <img
            src={Loader}
            alt="loading"
            className="flex justify-center items-center"
          />
        </div>
      )}
    </>
  );
};

export default Result;
