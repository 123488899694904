import { useState, useRef, useEffect } from "react";
import axios from "axios";
import audio from "../../Assets/Svgs/audio.svg";
import recording from "../../Assets/Gif/recording.gif";

function AudioRecorder({ onTextUpdate, onClearText, onLoading }) {
  const apiKey = process.env.REACT_APP_API_URL;
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);

  const mediaRecorderRef = useRef(null);
  const [digitalMicDeviceId, setDigitalMicDeviceId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [audio_id, setAudioId] = useState("");

  const getDigitalMicrophone = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });

      const devices = await navigator.mediaDevices.enumerateDevices();

      const digitalMic = devices.find(
        (device) =>
          device.kind === "audioinput" &&
          device.label.includes("Digital Microphone")
      );
      if (digitalMic) {
        setDigitalMicDeviceId(digitalMic.deviceId);
      } else {
        console.error("Digital Microphone not found.");
        setErrorMessage(
          "Digital Microphone not found. Using default microphone."
        );
      }
    } catch (error) {
      console.error("Error accessing devices or permissions denied:", error);
      setErrorMessage(
        "Microphone access denied. Please enable microphone permissions."
      );
    }
  };

  const startRecording = () => {
    onClearText();

    const audioConstraints = digitalMicDeviceId
      ? { audio: { deviceId: digitalMicDeviceId } }
      : { audio: true };
    navigator.mediaDevices
      .getUserMedia(audioConstraints)
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.start();
        mediaRecorder.ondataavailable = (e) => {
          setAudioBlob(e.data);
        };
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setErrorMessage(
          "Failed to start recording. Please check microphone permissions."
        );
      });
  };

  const handleSubmit = async () => {
    if (audioBlob) {
      const formData = new FormData();
      formData.append("audio_file", audioBlob, "recorded_audio.wav");
      try {
        const response = await axios.post(`${apiKey}/upload-audio/`, formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log(
        //   response.data,
        //   "-----------------response-------------------"
        // );
        // if (response.data.res === "") {
        //   return;
        // } else
        setAudioId(response.data.res.audio_id);
        // console.log(
        //   onTextUpdate,
        //   "----------------------speech to text-----------------------"
        // );
        setAudioBlob(null);
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage("Failed to upload audio. Please try again.");
      }
    }
  };
  const stopRecording = () => {
    onLoading();
    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        handleSubmit();
      };
    }

    setIsRecording(false);
  };

  useEffect(() => {
    if (audioBlob) {
      // console.log(audioBlob, "----------------------");
      handleSubmit();
    }
  }, [audioBlob]);

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      if (audio_id) {
        try {
          const response = await axios.get(
            `${apiKey}/audio_status/${audio_id}/`,
            {
              withCredentials: true,
            }
          );
          // console.log(response.data);
          if (response.data.status === "completed") {
            if (response.data.text === "") {
              onTextUpdate('" "');
            } else {
              onTextUpdate(response.data.text);
            }

            clearInterval(intervalId);
            // console.log("Processing completed, interval stopped");
          }
        } catch (error) {
          console.error(error);
          clearInterval(intervalId);
        }
      }
    };

    intervalId = setInterval(fetchData, 2000);
    return () => clearInterval(intervalId);
  }, [audio_id]);

  useEffect(() => {
    getDigitalMicrophone();
  }, []);
  return (
    <div>
      {isRecording ? (
        <div className="w-full flex justify-between">
          <button
            onClick={stopRecording}
            className="w-[212px] h-[44px] bg-[#2E2E30] text-white font-bold text-[16px] rounded-[6px] my-auto"
          >
            Stop Recording
          </button>
          <div>
            <img
              src={recording}
              alt="audio loading"
              className="h-[100px] w-[200px] "
            />
          </div>
          <div className="flex gap-2">
            <img src={audio} alt="recording" className="my-auto" />
            <div className="my-auto font-bold text-base">Recording</div>
          </div>
        </div>
      ) : (
        <button
          onClick={startRecording}
          className="w-[212px] h-[44px] bg-[#2E2E30] text-white font-bold text-[16px] rounded-[6px]"
        >
          Start Recording
        </button>
      )}
    </div>
  );
}
export default AudioRecorder;
